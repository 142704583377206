import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/ShiftTypeSelector.module.css';
import {getShiftsByDate} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {useMsal} from "@azure/msal-react";
import Button from "./Button";
import CustomShiftModal from "./CustomShiftModal";
import {temployee, tSelectShift, tshift} from "../helpers/types";
import CustomShiftSelect from "./CustomShiftSelect";
import moment from "moment";
import {HolidaysTypes} from "date-holidays";
import {useTranslation} from "react-i18next";
import {isAdmin} from "../helpers/checkers";
interface IProps
{
    selectedShift: tSelectShift,
    setSelectedShift: Dispatch<SetStateAction<tSelectShift>>,
    days: Date[],
    isUpdate: boolean,
    publicHolidays: HolidaysTypes.Holiday[];
    employeeEmail: string;
}

const ShiftTypeSelector: FC<IProps> = ({selectedShift, setSelectedShift, days, isUpdate, publicHolidays, employeeEmail}) =>
{
    const {accessToken, notificationDispatch, groupsID} = useContext(Context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const [shifts, setShifts] = useState<tshift[]>([]);
    const [availableShifts, setAvailableShifts] = useState<tSelectShift[]>([]);
    const [isSetShifts, setIsSetShifts] = useState<boolean>(false);

    const [isAddCustomShift, setIsAddCustomShift] = useState<boolean>(false);

    const lastDayInDaysArr = moment(days[days.length - 1]).startOf("day").toDate();

    useEffect(() =>
    {
        (async () =>
        {
            for(const day of days)
            {
                const shifts: tshift[] = await getShiftsByDate(accessToken, day, notificationDispatch);
                if(shifts)
                {
                    setShifts(current => [...current, ...shifts]);
                }
            }
            setIsSetShifts(true);
        })();
    }, []);

    useEffect(() =>
    {
        if(isSetShifts)
        {
            const properEmail = isAdmin(groupsID) ? employeeEmail.toLowerCase() : accounts[0].username.toLowerCase();

            const morningTime = {from: (process.env.REACT_APP_MORNING_SHIFT as string).split("-")[0], to: (process.env.REACT_APP_MORNING_SHIFT as string).split("-")[1]};
            const dayTime = {from: (process.env.REACT_APP_DAY_SHIFT as string).split("-")[0], to: (process.env.REACT_APP_DAY_SHIFT as string).split("-")[1]};
            const eveningTime = {from: (process.env.REACT_APP_EVENING_SHIFT as string).split("-")[0], to: (process.env.REACT_APP_EVENING_SHIFT as string).split("-")[1]};
            const weekendTime = {from: `${(process.env.REACT_APP_WEEKEND_MORNING as string).split("-")[0]}${(process.env.REACT_APP_WEEKEND_EVENING as string).split("-")[0]}`, to: `${(process.env.REACT_APP_WEEKEND_MORNING as string).split("-")[1]}${(process.env.REACT_APP_WEEKEND_EVENING as string).split("-")[1]}`};

            const customShifts: tSelectShift[] = shifts.filter(shift => !["morning", "day", "evening", "vacation", "weekend check", "sickLeave"].includes(shift.type))
                .map(shift => {return {type: shift.type, isHalf: shift.isHalf, isHalfAvailable: shift.isHalfAvailable, durationType: shift.durationType,
                    fullTimeDuration: {from: shift.fullTimeDuration.from, to: shift.fullTimeDuration.to}}})
                .reduce((accumulator: tSelectShift[], currentShift) =>
                {
                    if(!accumulator.find(shift=> shift.type.toLowerCase() === currentShift.type.toLowerCase())) accumulator.push(currentShift);

                    return accumulator;
                }, []);

            const weekdaysDefShifts: tSelectShift[] = [{type: "morning", isHalf: false, isHalfAvailable: true, durationType: 0, fullTimeDuration: morningTime},
                {type: "day", isHalf: false, isHalfAvailable: true, durationType: 0, fullTimeDuration: dayTime},
                {type: "evening", isHalf: false, isHalfAvailable: true, durationType: 0, fullTimeDuration: eveningTime}]

            const weekendsDefShifts: tSelectShift[] = [{type: "weekend check", isHalf: false, isHalfAvailable: false, durationType: 0, fullTimeDuration: weekendTime}]

            const numberOfWeekendDaysInArr = days.filter(day => moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7).length;
            const numberOfHolidaysInArr = days.filter(day => publicHolidays.filter(holiday => moment(holiday.date).isSame(day, "day")).length).length

            const defaultShifts: tSelectShift[] = numberOfHolidaysInArr === days.length ? weekendsDefShifts :
                days.length > 1 ? numberOfWeekendDaysInArr === days.length
                    ? weekendsDefShifts : moment(lastDayInDaysArr).isoWeekday() === 6 || moment(lastDayInDaysArr).isoWeekday() === 7 || numberOfHolidaysInArr >= 1 ? [] : weekdaysDefShifts
                : moment(days[0]).isoWeekday() === 6 || moment(days[0]).isoWeekday() === 7 ? weekendsDefShifts : weekdaysDefShifts;

            defaultShifts.push(...customShifts);

            for(const defaultShift of defaultShifts)
            {

                const existingFullShift = shifts.filter(shift => shift.type.toLowerCase() === defaultShift.type.toLowerCase() && !shift.isHalf);
                const existingHalfShift = shifts.filter(shift => shift.type.toLowerCase() === defaultShift.type.toLowerCase() && shift.isHalf);

                const foundOwnFullShift = existingFullShift.filter(shift => (shift.userID as temployee).email.toLowerCase() === properEmail)
                const isExistingFullShiftOwn = foundOwnFullShift.length === 1;

                const foundOwnHalfShift = existingHalfShift.filter(shift => (shift.userID as temployee).email.toLowerCase() === properEmail);
                const isExistingHalfShiftOwn = foundOwnHalfShift.length === 1;

                if((!existingFullShift.length && !existingHalfShift.length) || (isUpdate && !existingHalfShift.length && isExistingFullShiftOwn))
                {
                    const fullShift: tSelectShift = {type: defaultShift.type, isHalf: defaultShift.isHalf, isHalfAvailable: defaultShift.isHalfAvailable, durationType: defaultShift.durationType,
                        fullTimeDuration: {from: defaultShift.fullTimeDuration.from, to: defaultShift.fullTimeDuration.to} };
                    isExistingFullShiftOwn && setSelectedShift(fullShift);

                    setAvailableShifts(current => [...current, fullShift])
                } else if(existingHalfShift.length === 1 || (existingHalfShift.length && isUpdate && isExistingHalfShiftOwn))
                {
                    const halfShift: tSelectShift = {type: defaultShift.type, isHalf: true, isHalfAvailable: true,
                        durationType: isExistingHalfShiftOwn ? foundOwnHalfShift[0].durationType : existingHalfShift[0].durationType === 1 ? 2 : 1, otherExists: existingHalfShift.length === 1 && !isExistingHalfShiftOwn ||
                            existingHalfShift.length === 2, fullTimeDuration: {from: defaultShift.fullTimeDuration.from, to: defaultShift.fullTimeDuration.to}};
                    isExistingHalfShiftOwn && setSelectedShift(halfShift);

                    setAvailableShifts(current => [...current, halfShift])
                }

                const isOwnVacationExists = shifts.filter(shift => shift.type.toLowerCase() === "vacation" && (shift.userID as temployee).email.toLowerCase() === properEmail);
                const isOwnSickLeaveExists = shifts.filter(shift => shift.type.toLowerCase() === "sickLeave" && (shift.userID as temployee).email.toLowerCase() === properEmail);

                isOwnVacationExists.length === 1 && setSelectedShift(isOwnVacationExists[0]);
                isOwnSickLeaveExists.length === 1 && setSelectedShift(isOwnSickLeaveExists[0]);

            }

            setAvailableShifts(current => [...current, {type: "vacation", isHalf: false, isHalfAvailable: false, durationType: 0,
                fullTimeDuration: {from: "00:00", to: "00:00"}}, {type: "sickLeave", isHalf: false, isHalfAvailable: false, durationType: 0,
                fullTimeDuration: {from: "00:00", to: "00:00"}}]);

        }
    }, [isSetShifts]);

    return (
        <div className={styles.selector_container} onClick={e => e.stopPropagation()}>

            <CustomShiftSelect shifts={availableShifts} selectedShift={selectedShift} setSelectedShift={setSelectedShift} />
            <Button iconType="add" onClick={() => setIsAddCustomShift(current => !current)}>{t("shiftTypeSelector.customShift")}</Button>

            {isAddCustomShift &&
                <CustomShiftModal setIsAddCustomShift={setIsAddCustomShift} setAvailableShifts={setAvailableShifts} selectedDate={days[0]} />
            }
        </div>
    );
}

export default ShiftTypeSelector;
