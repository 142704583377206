import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import styles from "./css/WeekFilter.module.css";
import moment from "moment/moment";
import {calculateMonth, getProperSlices} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";

interface IProps
{
    monthDays: Date[];
    setMonthDays: Dispatch<SetStateAction<Date[]>>;
    firstSlice: number;
    setFirstSlice: Dispatch<SetStateAction<number>>;
    secondSlice: number;
    setSecondSlice: Dispatch<SetStateAction<number>>;
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    setMobileSelectedDay: Dispatch<SetStateAction<Date>>;
}

const WeekFilter: FC<IProps> = ({monthDays, setMonthDays, firstSlice, setFirstSlice, secondSlice, setSecondSlice, setSelectedDays, setMobileSelectedDay}) =>
{
    const {t} = useTranslation();

    const today = moment(new Date(Date.now())).startOf("day");
    const monthEndDate = moment(today).endOf("month");

    useEffect(() =>
    {
        if(moment(today).isSame(monthEndDate, "week") || moment(today).isoWeekday() !== 1)
        {
            handleAddMonth(firstSlice, secondSlice, true);
        }
    },[monthDays])

    const handleWeekFilter = (isBack: boolean = false) =>
    {
        let newFirstSlice;
        let newSecondSlice;

        if(isBack)
        {
            newFirstSlice = firstSlice - 7;
            newSecondSlice = secondSlice -7;
        } else
        {
            newFirstSlice = secondSlice;
            newSecondSlice = secondSlice + 7;
        }

        setFirstSlice(newFirstSlice);
        setSecondSlice(newSecondSlice);

        setSelectedDays([]);
        handleAddMonth(newFirstSlice, newSecondSlice);
    }

    const handleAddMonth = (firstSlice: number, secondSlice: number, isFirstLaunch: boolean = false) =>
    {
        if(firstSlice <= 0)
        {
            const firstDate = monthDays[0];
            const prevMonth = moment(firstDate).subtract(1, "month").startOf("month").toDate();
            const newMonth = [...calculateMonth(prevMonth), ...monthDays];

            setMonthDays(newMonth);
            getProperSlices(firstDate, newMonth, setFirstSlice, setSecondSlice);

        } else if(monthDays.length - secondSlice < 7)
        {
            const currentDate = monthDays[firstSlice];
            const newMonth = [...monthDays, ...calculateMonth(moment(currentDate).add(1, "month").toDate())]

            setMonthDays(newMonth)
            getProperSlices(currentDate, newMonth, setFirstSlice, setSecondSlice);
        }

        if(!isFirstLaunch) setMobileSelectedDay(monthDays[firstSlice]);
    }

    return (
        <div className={styles.week_filter}>
            {`${t("app.week")}: ${moment(monthDays[firstSlice]).isoWeek()} / ${moment(monthDays[firstSlice]).weekYear()}`}
            <div className={styles.controls}>
                <button onClick={() => handleWeekFilter(true)}> {"<"} </button>
                <span>{monthDays.length && `${moment(monthDays[firstSlice]).format("DD.MM.yyyy")} - ${moment(monthDays[secondSlice - 1]).format("DD.MM.yyyy")}`}</span>
                <button onClick={() => handleWeekFilter()}> {">"} </button>
            </div>
        </div>
    );
}

export default WeekFilter;
