import React, {Dispatch, FC, SetStateAction} from 'react';
import styles from "./css/Search.module.css"
import {Icons} from "../helpers/Icons";

interface IProps
{
    placeholder: string;
    onChange: Dispatch<SetStateAction<string>>,
    width?: string
}

const Search: FC<IProps> = ({placeholder, onChange, width}) =>
{
    return (
        <div className={styles.search} style={{width: width}}>
            {Icons.search}
            <input type="search" placeholder={placeholder} onChange={e => onChange(e.target.value)} />
        </div>
    );
}

export default Search;
