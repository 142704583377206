import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from './css/EditMultipleDaysModal.module.css';
import modalStyles from './css/ModalStyles.module.css';
import Button from "./Button";
import moment from "moment/moment";
import Context from "../helpers/Context";
import {updateDay} from "../helpers/APIEnpoints";
import ReactQuill from "react-quill";
import ChangesNotSavedModal from "./ChangesNotSavedModal";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";

interface IProps
{
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    setIsEditMultipleDays: Dispatch<SetStateAction<boolean>>;
}

const EditMultipleDaysModal: FC<IProps> = ({selectedDays, setSelectedDays, setIsEditMultipleDays}) =>
{
    const {accessToken, notificationDispatch, setLoadingMessage, setIsLoading} = useContext(Context);
    const {t} = useTranslation();

    const [notes, setNotes] = useState<string>("");
    const [comments, setComments] = useState<string>("");

    const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true);
    const [isCloseClicked, setIsCloseClicked] = useState<boolean>(false);

    useEffect(() =>
    {
        if(comments.trim().toLowerCase() === "<p><br></p>")
        {
            setComments("");
        }

        if(notes.trim().toLowerCase() === "<p><br></p>")
        {
            setNotes("");
        }

        if(comments.length || notes.length)
        {
             setIsChangesSaved(false)
        } else
        {
             setIsChangesSaved(true)
        }

    }, [notes, comments])

    const handleEditDays = async () =>
    {
        setIsLoading(true);
        setLoadingMessage(t("loadingMessages.updatingSelectedDays") as string);

        for(const selectedDay of selectedDays)
        {
            await updateDay(accessToken, selectedDay, {notes, comments}, notificationDispatch, true);

            setIsChangesSaved(true);
        }

        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "success",
                    message: t(`backEnd.dayUpdateSuccess`)
                }
        })

        handleClose();
    }

    const handleClose = () =>
    {
        setIsEditMultipleDays(current => !current);
        setSelectedDays([]);
        setIsLoading(false);
        setLoadingMessage("");
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}
             onTouchEnd={() => isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.edit_container}>

                        <div className={modalStyles.modal_top}>
                            {t("editMultipleDaysModal.title")} ({selectedDays.length} {t("app.days")}):
                            <div className={modalStyles.selected_days_list}>
                                {selectedDays.map((day, index) => <span key={index}>{moment(day).format("DD.MM.yyyy")}</span>)}
                            </div>
                            <span className={styles.subtitle}>{t("editMultipleDaysModal.warningMessage")}</span>
                        </div>

                        <div className={styles.center}>
                            <div className={styles.notes}>
                                <span>{`${t("app.notes")} ${!isChangesSaved && notes.length ? "*" : ""}`}</span>
                                <div className={styles.text_editor_container}>
                                    <ReactQuill theme="snow" className={styles.text_editor} value={notes} onChange={setNotes} />
                                </div>
                            </div>

                            <div className={styles.comments}>
                                <span>{`${t("app.comments")} ${!isChangesSaved && comments.length ? "*" : ""}`}</span>
                                <div className={styles.text_editor_container}>
                                    <ReactQuill theme="snow" className={styles.text_editor} value={comments} onChange={setComments} />
                                </div>
                            </div>
                        </div>


                        <div className={modalStyles.modal_controls}>
                            <Button btnType="danger" onClick={() => isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}>{t("app.cancel")}</Button>
                            <Button onClick={handleEditDays} disabled={isChangesSaved}>{t("app.confirm")}</Button>
                        </div>

                    </div>

            </div>
        </div>

            {isCloseClicked &&
                <ChangesNotSavedModal handleCloseDayModal={handleClose} setIsCloseClicked={setIsCloseClicked} />
            }

    </div>
    );
}

export default EditMultipleDaysModal;
