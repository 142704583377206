import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/ControlsFilters.module.css";
import Search from "./Search";
import Button from "./Button";
import {temployee, tshift} from "../helpers/types";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import AddMultipleShiftsModal from "./AddMultipleShiftsModal";
import EditMultipleDaysModal from "./EditMultipleDaysModal";
import DeleteMultipleShiftsModal from "./DeleteMultipleShiftsModal";
import {HolidaysTypes} from "date-holidays";
import {upperCaseFirstLetter} from "../helpers/helperFunctions";
import {isAdmin, isUserAdmin} from "../helpers/checkers";
import Context from "../helpers/Context";

type tDefaultProps =
{
    isSearchAvailable?: boolean
    isDeleteMultipleShiftsAvailable?: boolean;
    isEditMultipleDaysAvailable?: boolean;
    isAddMultipleShiftsAvailable?: boolean;
    isMobile?: boolean;
    employees: temployee[];
}

type tSearch =
{
    isSearchAvailable?: true,
    setSearchQuery: Dispatch<SetStateAction<string>>;
    searchBy: string;
} |
{
    isSearchAvailable?: false,
    setSearchQuery?: never;
    searchBy?: never;
}

type tDeleteMultipleShifts =
{
    isDeleteMultipleShiftsAvailable?: true,
    setSelectedShifts: Dispatch<SetStateAction<tshift[]>>;
    setEmployees: Dispatch<SetStateAction<temployee[]>>;
    selectedShifts: tshift[];
} |
{
    isDeleteMultipleShiftsAvailable?: false,
    setSelectedShifts?: never;
    setEmployees?: never;
    selectedShifts?: never;
}

type tEditMultipleDays =
{
    isEditMultipleDaysAvailable?: true,
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
} |
{
    isEditMultipleDaysAvailable?: false,
    selectedDays?: never;
    setSelectedDays?: never;
}

type tAddMultipleShifts =
{
    isAddMultipleShiftsAvailable?: true,
    currentEmployee: temployee;
    publicHolidays: HolidaysTypes.Holiday[];
} |
{
    isAddMultipleShiftsAvailable?: false,
    currentEmployee?: never;
    publicHolidays?: never;
}

type props = tDefaultProps & tSearch & tDeleteMultipleShifts & tEditMultipleDays & tAddMultipleShifts;

const ControlsFilters: FC<props> = ({setSearchQuery, selectedDays, currentEmployee, selectedShifts, publicHolidays, employees, setSelectedDays, isSearchAvailable = true, isDeleteMultipleShiftsAvailable = true, setSelectedShifts, setEmployees, isEditMultipleDaysAvailable = true, isAddMultipleShiftsAvailable= true, searchBy = "name", isMobile = false}) =>
{
    const {groupsID} = useContext(Context);
    const {t} = useTranslation();

    const [isAddMultipleShifts, setIsAddMultipleShifts] = useState<boolean>(false);
    const [isEditMultipleDays, setIsEditMultipleDays] = useState<boolean>(false);
    const [isDeleteMultipleShift, setIsDeleteMultipleShifts] = useState<boolean>(false);

    useEffect(() =>
    {
        if(isAddMultipleShifts || isEditMultipleDays || isDeleteMultipleShift)
        {
            document.getElementsByTagName("body")[0].style.overflow = "hidden"
        } else
        {
            document.getElementsByTagName("body")[0].style.overflow = "auto"
        }

    }, [isAddMultipleShifts, isEditMultipleDays, isDeleteMultipleShift])

    return (
        <div className={styles.filters}>
            <div className={styles.search_controls_filters}>
                {isSearchAvailable && <Search onChange={setSearchQuery!} placeholder={`${t(`app.search`)}${t(`app.by${upperCaseFirstLetter(searchBy)}`)}`} width="250px" />}
                <div className={styles.filters_controls}>
                    {isEditMultipleDaysAvailable && isUserAdmin(groupsID) &&
                        <Button btnMini={isMobile} iconType="edit" disabled={selectedDays!.length < 2} onClick={() => setIsEditMultipleDays(current => !current)}>{t("app.editSelectedDays")}</Button>
                    }

                    {isAddMultipleShiftsAvailable && isUserAdmin(groupsID) &&
                        <Button btnMini={isMobile} iconType="add" disabled={selectedDays!.length < 2 || (currentEmployee !== undefined &&
                                (currentEmployee.shifts as tshift[]).filter(shift =>
                                    selectedDays!.filter(day => moment(day).isSame(shift.date, "day")).length).length >= 1) ||
                            selectedDays!.filter(day => moment(day).isBefore(new Date(Date.now()), "day")).length >= 1}
                                onClick={() => setIsAddMultipleShifts(current => !current)}>{t("app.addMultipleShifts")}</Button>
                    }

                    {isDeleteMultipleShiftsAvailable && isUserAdmin(groupsID) &&
                        <Button btnMini={isMobile} iconType="delete" disabled={(selectedShifts!.length < 2 ||
                            selectedShifts!.filter(shift => moment(shift.date).isSameOrBefore(new Date(Date.now()), "day")).length >= 1 && !isAdmin(groupsID))}
                                btnType="danger" onClick={() => setIsDeleteMultipleShifts(current => !current)}>{t("app.deleteMultipleShifts")}</Button>
                    }
                </div>
            </div>

            {isAddMultipleShifts && isUserAdmin(groupsID) &&
                <AddMultipleShiftsModal employees={employees!} selectedDays={selectedDays!}
                                        setSelectedDays={setSelectedDays!} setIsAddMultipleShifts={setIsAddMultipleShifts} publicHolidays={publicHolidays!} />
            }

            {isEditMultipleDays && isUserAdmin(groupsID) &&
                <EditMultipleDaysModal selectedDays={selectedDays!} setSelectedDays={setSelectedDays!} setIsEditMultipleDays={setIsEditMultipleDays}/>
            }

            {isDeleteMultipleShift && isUserAdmin(groupsID) &&
                <DeleteMultipleShiftsModal selectedShifts={selectedShifts!}
                                           setSelectedShifts={setSelectedShifts!}
                                           setIsDeleteMultipleShifts={setIsDeleteMultipleShifts} employees={employees!} setEmployees={setEmployees!} />
            }

        </div>
    );
}

export default ControlsFilters;
