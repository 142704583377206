import {AccountInfo} from "@azure/msal-common";
import {IPublicClientApplication} from "@azure/msal-browser";
import {Dispatch, SetStateAction} from "react";
import {loginRequest} from "../authConfig";
import {taddShift, notificationDispatch, tSelectShift, tNotificationsPreference} from "./types";
import {browserName, deviceType, mobileModel, osName} from 'react-device-detect';
import {v4} from "uuid";
import {t} from "i18next";
import moment from "moment";

export const RequestAccessToken = (accounts: AccountInfo[], instance: IPublicClientApplication, setAccessToken: Dispatch<SetStateAction<string>>): void =>
{
    const request =
        {
            ...loginRequest,
            account: accounts[0]
        };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) =>
    {
        setAccessToken(response.accessToken);
    }).catch((e) =>
    {
        instance.acquireTokenPopup(request).then((response) =>
        {
            setAccessToken(response.accessToken);
        });
    });
}

export const getAllEmployees = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/user`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const addShift = async (accessToken: string, shiftToAdd: taddShift, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    const {employeeEmail, date, type, isHalf, isHalfAvailable, durationType, fullTimeDuration } = shiftToAdd;

    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/shift`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "email": employeeEmail,
                    "date": date,
                    "type": type,
                    "isHalf": isHalf,
                    "isHalfAvailable": isHalfAvailable,
                    "durationType": durationType,
                    "fullTimeDuration": fullTimeDuration
                })
            })
            .then(response => response.json()).then(result =>
            {
                if(result.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: result.loc ? t(`backEnd.${result.loc}`) : result.error
                            }
                    })
                }

                console.log(result);

                return result.data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getDayInfo = async (accessToken: string, date: Date, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/day/${date}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const updateDay = async (accessToken: string, date: Date, updateInfo: {notes: string, comments: string}, notificationDispatch: Dispatch<notificationDispatch>, isMulti: boolean = false) =>
{
    const {notes, comments} = updateInfo;

    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/day`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "notes": notes.trim(),
                    "comments": comments.trim(),
                    "date": date,
                    "isMulti": isMulti
                })
            })
            .then(response => response.json()).then(result =>
            {
                if(result.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: result.loc ? t(`backEnd.${result.loc}`) : result.error
                            }
                    })
                }

                return result.data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getShiftsByDate = async (accessToken: string, date: Date, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/shift/${date}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const changeShift = async (accessToken: string, shiftID: string, selectedShift: tSelectShift, date: Date, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    const {type, isHalf, durationType, fullTimeDuration} = selectedShift;

    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/shift/${shiftID}`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "type": type,
                    "isHalf": isHalf,
                    "durationType": durationType,
                    "fullTimeDuration": fullTimeDuration,
                    "date": date
                })
            })
            .then(response => response.json()).then(result =>
            {
                if(result.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: result.loc ? t(`backEnd.${result.loc}`) : result.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${result.loc}`)
                        }
                })

                return result.data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const deleteShift = async (accessToken: string, id: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/shift/${id}`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(result =>
            {
                if(result.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: result.loc ? t(`backEnd.${result.loc}`) : result.error
                            }
                    })
                }

                console.log(result);

                return result
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const savePushNotificationSubscription = async (subscription: PushSubscription, notificationsPreference: tNotificationsPreference, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/subscribe`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    notificationsPreference: notificationsPreference
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data;
            });
    } catch (err)
    {
        if(notificationDispatch)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: (err as string)
                    }
            })
        }

        return console.log(err);
    }
}

export const renewUserSubscription = async (subscription: PushSubscription, id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/`,
            {
                method: "PATCH",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: id,
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data => data);
    } catch (err)
    {
        return console.log(err);
    }
}

export const deletePushNotificationSubscription = async (id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/${id}`,
            {
                method: "DELETE",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data =>
            {
                console.log(data);
            });
    } catch (err)
    {
        console.log(err);
    }
}

export const getInactiveShiftsByMonth = async (accessToken: string, month: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/shift/inactive/${month}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getStatsByMonth = async (accessToken: string, month: string, shiftType: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/stats/${month}/${shiftType}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getUser = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/user/one`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAdditionalPayment = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>, month: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/additionalPayment/${month}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json());
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const updateAdditionalPayment = async (accessToken: string, additionalPayment: string, notificationDispatch: Dispatch<notificationDispatch>, month: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/additionalPayment/`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    additionalPayment: additionalPayment,
                    month: month
                })
            })
            .then(response => response.json()).then(result =>
            {
                if(result.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: result.loc ? t(`backEnd.${result.loc}`) : result.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${result.loc}`)
                        }
                })

                return result.data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getNotificationsPreference = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/get-preferences`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName
                })
            })
            .then(response => response.json()).then(data => data);
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const updateNotificationsPreference = async (notificationsPreference: tNotificationsPreference, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/update-preferences`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    notificationsPreference: notificationsPreference

                })
            })
            .then(response => response.json()).then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const deleteUserByID = async (userID: string, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/user/${userID}`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(result =>
            {
                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t("backEnd.userWithIDDeletedSuccess")} ${userID} ${t("backEnd.userDeletedSuccess")}`
                        }
                })
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}
