import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from "./css/DeleteMultipleShiftsModal.module.css";
import modalStyles from "./css/ModalStyles.module.css";
import moment from "moment";
import Button from "./Button";
import {temployee, tshift} from "../helpers/types";
import Context from "../helpers/Context";
import {handleDeleteShift, upperCaseFirstLetter} from "../helpers/helperFunctions";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {t} from "i18next";
import {isAdmin} from "../helpers/checkers";

interface IProps
{
    selectedShifts: tshift[];
    setSelectedShifts: Dispatch<SetStateAction<tshift[]>>;
    setIsDeleteMultipleShifts: Dispatch<SetStateAction<boolean>>;
    employees: temployee[];
    setEmployees: Dispatch<SetStateAction<temployee[]>>;
}

const DeleteMultipleShiftsModal: FC<IProps> = ({selectedShifts, setSelectedShifts, setIsDeleteMultipleShifts, employees, setEmployees}) =>
{
    const {accessToken, notificationDispatch, setIsLoading, setLoadingMessage, groupsID} = useContext(Context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const handleMultipleShiftDelete = async () =>
    {
        setIsLoading(true);
        setLoadingMessage(t("loadingMessages.deletingShifts") as string);

        const isAdminDeletion = isAdmin(groupsID);

        let index: number = 0;
        if(!isAdminDeletion) index = employees.findIndex(employee => employee.email.toLowerCase() === accounts[0].username.toLowerCase());

        for(const selectedShift of selectedShifts)
        {
            if(isAdminDeletion) index = employees.findIndex(employee => employee._id.toLowerCase() === (selectedShift.userID as string).toLowerCase());
            await handleDeleteShift(selectedShift._id, accessToken, employees, employees[index], setEmployees, notificationDispatch)
        }

        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "success",
                    message: t(`backEnd.shiftDeleteSuccess`)
                }
        })

        handleClose();
    }

    const handleClose = () =>
    {
        setIsDeleteMultipleShifts(current => !current);
        setSelectedShifts([]);
        setIsLoading(false);
        setLoadingMessage("");
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.delete_container}>

                        <div className={modalStyles.modal_top}>
                            {t("deleteMultipleShiftsModal.title")} ({selectedShifts.length} {t("app.shifts")}):
                            <div className={modalStyles.selected_days_list}>
                                {selectedShifts.map(shift => <span key={shift._id}>{upperCaseFirstLetter(t(`shiftCard.${shift.type.replace(" ", "")}`))}: {moment(shift.date).format("DD.MM.yyyy")}</span>)}
                            </div>
                        </div>

                        <div className={modalStyles.modal_controls}>
                            <Button btnType="danger" onClick={handleClose}>{t("app.cancel")}</Button>
                            <Button onClick={handleMultipleShiftDelete}>{t("app.confirm")}</Button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default DeleteMultipleShiftsModal;
