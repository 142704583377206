// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

/*import {
  deletePushNotificationSubscription,
  renewUserSubscription,
  savePushNotificationSubscription
} from "./helpers/APIEnpoints";
import {Dispatch} from "react";
import {notificationDispatch} from "./helpers/types";
import {del, get, set} from 'idb-keyval';*/

import {del, get, set} from "idb-keyval";
import {
  deletePushNotificationSubscription,
  renewUserSubscription,
  savePushNotificationSubscription
} from "./helpers/APIEnpoints";
import {Dispatch} from "react";
import {notificationDispatch, tNotificationsPreference} from './helpers/types';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if ( process.env.NODE_ENV === 'production' &&'serviceWorker' in navigator)
  {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(`${process.env.PUBLIC_URL}`, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) =>
    {
      const subscription = await registration.pushManager.getSubscription();
      const id = await get("subscriptionID");
      const user = await get("email");
      const endpoint = await get("subscriptionEndpoint");
      if(subscription === null && id !== undefined)
      {
        await del("subscriptionID");
        await del("email");
        await del("subscriptionEndpoint");
        await deletePushNotificationSubscription(id, user);
      }

      if(subscription !== null && subscription.endpoint !== endpoint)
      {
        await set("subscriptionEndpoint", subscription.endpoint);
        await renewUserSubscription(subscription, id, user);
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                  'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

const urlBase64ToUint8Array = (base64String: string) =>
{
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
  {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const notificationSubscription = async (notificationsPreference: tNotificationsPreference, serviceWorker: ServiceWorkerRegistration, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
  try
  {
    let subscription = await serviceWorker.pushManager.getSubscription();

    if(subscription !== null) await subscription.unsubscribe();
    const applicationServerKey = urlBase64ToUint8Array(`${process.env.REACT_APP_PUBLIC_VAPID_KEY}`);
    const options = { applicationServerKey, userVisibleOnly: true };

    subscription = await serviceWorker.pushManager.subscribe(options);
    const response = await savePushNotificationSubscription(subscription, notificationsPreference, accessToken, notificationDispatch);
    await set("subscriptionID", response.subscription._id);
    await set("email", response.subscription.user);
    await set("subscriptionEndpoint", response.subscription.subscription.endpoint);

  } catch (err)
  {
    console.log(err)
  }
}

export const subscribeUserToPush = (notificationsPreference: tNotificationsPreference, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
  if ('serviceWorker' in navigator)
  {
    return navigator.serviceWorker.ready
        .then(async registration => await notificationSubscription(notificationsPreference, registration, notificationDispatch, accessToken))
        .catch(err => console.log(err))
  }
}

const invokeServiceWorkerUpdateFlow = (registration: ServiceWorkerRegistration) =>
{
  // TODO implement your own UI notification element
  if (registration.waiting)
  {
    // let waiting Service Worker know it should become active
    registration.waiting.postMessage('SKIP_WAITING')
  }
}

// Checking browser support of service workers
if ('serviceWorker' in navigator)
{
  // Waiting page load
  window.addEventListener('load', async () =>
  {
    // Registering service worker
    const registration = await navigator.serviceWorker.register('/service-worker.js')

    // Ensuring that if the update found event was missed it will re-invoke the event if the waiting service worker exists.
    if (registration.waiting)
    {
      invokeServiceWorkerUpdateFlow(registration)
    }

    // Detecting Service Worker update found and wait for it to become installed
    registration.addEventListener('updatefound', () =>
    {
      if (registration.installing)
      {
        // Wait until the new Service worker is actually installed (ready to take over)
        registration.installing.addEventListener('statechange', () =>
        {
          if (registration.waiting)
          {
            // if there's an existing controller (previous Service Worker), force the update
            if (navigator.serviceWorker.controller)
            {
              invokeServiceWorkerUpdateFlow(registration)
            } else
            {
              // otherwise it's the first install, nothing to do
              console.log('Service Worker initialized for the first time')
            }
          }
        })
      }
    })

    let refreshing = false;

    // Detect controller change and refresh the page
    navigator.serviceWorker.addEventListener('controllerchange', () =>
    {
      if (!refreshing)
      {
        window.location.reload()
        refreshing = true
      }
    })
  })
}
