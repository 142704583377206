import DatePicker from "react-datepicker";
import React, {Dispatch, FC, SetStateAction} from "react";
import moment from "moment/moment";
import styles from "./css/DatePickers.module.css";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";

export const filterPassedTime = (time: Date) =>
{
    console.log(time)

    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

const filterStartDatePassedTime = (time: Date, startDate: Date) =>
{
    const currentDate = startDate;
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

interface IStartTimeProps
{
    startTime: Date;
    endTime: Date;
    setStartTime: Dispatch<SetStateAction<Date>>;
    setEndTime: Dispatch<SetStateAction<Date>>;
}

export const StartTime: FC<IStartTimeProps> = ({startTime, endTime, setStartTime, setEndTime}) =>
{
    const {t} = useTranslation();

    return(
        <label>
            {t("app.from")}
            <DatePicker selected={startTime}
                        onChange={(date) =>
                        {
                            setStartTime(date!);
                            if(moment(endTime).isSameOrBefore(date, "minute")) setEndTime(moment(date!).add(30, "minute").toDate());
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        filterTime={filterPassedTime}
                        timeIntervals={30}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={styles.date_picker}
                        timeCaption={t("app.time").toString()} />
        </label>
    )
}

interface IEndTimeProps
{
    startTime: Date;
    endTime: Date;
    setEndTime: Dispatch<SetStateAction<Date>>;
}

export const EndTime: FC<IEndTimeProps> = ({startTime, endTime, setEndTime}) =>
{
    const {t} = useTranslation();

    return(
        <label>
            {t("app.to")}
            <DatePicker selected={endTime}
                        onChange={(date) => setEndTime(date!)}
                        showTimeSelect
                        showTimeSelectOnly
                        filterTime={date => filterStartDatePassedTime(date, startTime!)}
                        timeIntervals={30}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={styles.date_picker}
                        timeCaption={t("app.time").toString()} />
        </label>
    )
}
