import React, {ChangeEvent, FC, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {handleNotifications} from "../helpers/helperFunctions";
import Button from "../utilities/Button";
import Context from "../helpers/Context";
import styles from './css/NotificationsSelector.module.css';
import {getNotificationsPreference, updateNotificationsPreference} from "../helpers/APIEnpoints";
import {tNotificationsPreference} from "../helpers/types";
import {isAdmin} from "../helpers/checkers";

interface IProps
{
    isMobile?: boolean;
}

const NotificationsSelector: FC<IProps> = ({isMobile = false}) =>
{
    const {t} = useTranslation();
    const {accessToken, notificationDispatch, setHideNotificationPrompt, groupsID} = useContext(Context);

    const [notificationsPreference, setNotificationsPreference] = useState<tNotificationsPreference>({
        _id: "", isManager: false,
        isMissingShift: true,
        isShiftEnd: true,
        isShiftStart: true,
        isTomorrowShift: true
    });

    const [isShowNotificationSettings, setIsShowNotificationSettings] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const [isSubscriptionExists, setIsSubscriptionExists] = useState(false);

    useEffect(() =>
    {
        document.addEventListener('mousedown', (event) =>
        {
            if (ref.current && !ref.current.contains(event.target as Node)) setIsShowNotificationSettings(false);
        });
    }, [ref]);

    useEffect(() =>
    {
        (async () =>
        {
            const preferences = await getNotificationsPreference(accessToken, notificationDispatch);

            if(preferences)
            {
                setNotificationsPreference(preferences);
                setIsSubscriptionExists(true)
            } else
            {
                setIsSubscriptionExists(false);
            }
        })();
    }, []);

    const handleNotificationsPreferenceChange = (e: ChangeEvent<HTMLInputElement>) =>
    {
        switch (e.target.value)
        {
            case "isShiftStart":
                return notificationsPreference.isShiftStart = e.target.checked;
            case "isShiftEnd":
                return notificationsPreference.isShiftEnd = e.target.checked;
            case "isMissingShift":
                return notificationsPreference.isMissingShift = e.target.checked;
            case "isTomorrowShift":
                return notificationsPreference.isTomorrowShift = e.target.checked;
        }
    }

    const handleNotificationCreation = async () =>
    {
        await handleNotifications(notificationsPreference, accessToken, notificationDispatch, setHideNotificationPrompt);
        setIsShowNotificationSettings(false);
    }

    const handleNotificationChange = async () =>
    {
        await updateNotificationsPreference(notificationsPreference, accessToken, notificationDispatch);
        setIsShowNotificationSettings(false);
    }

    return (
        <div className={styles.container}>
            <Button onClick={() => setIsShowNotificationSettings(current => !current)}>{isSubscriptionExists ? t("app.manageNotifications") : t("app.notificationPermission")}</Button>

            {isShowNotificationSettings &&
                <div className={`${styles.preference_container} ${isMobile ? styles.mobile_container : null}`} ref={ref}>
                    <h2>{t("app.selectNotifications")}</h2>
                    <label>
                        <input type="checkbox" onChange={e => handleNotificationsPreferenceChange(e)} value="isShiftStart"
                               defaultChecked={notificationsPreference.isShiftStart} />
                        {t("app.shiftStart")}
                    </label>
                    <label>
                        <input type="checkbox" onChange={e => handleNotificationsPreferenceChange(e)} value="isShiftEnd"
                               defaultChecked={notificationsPreference.isShiftEnd}/>
                        {t("app.shiftEnds")}
                    </label>
                    <label>
                        <input type="checkbox" onChange={e => handleNotificationsPreferenceChange(e)} value="isTomorrowShift"
                               defaultChecked={notificationsPreference.isTomorrowShift}/>
                        {t("app.isTomorrowShift")}
                    </label>

                    {isAdmin(groupsID) &&
                        <label>
                            <input type="checkbox" onChange={e => handleNotificationsPreferenceChange(e)} value="isMissingShift"
                                   defaultChecked={notificationsPreference.isMissingShift}/>
                            {t("app.missingShifts")}
                        </label>
                    }

                    <div className={styles.controls}>
                        <Button btnType="danger" onClick={() => setIsShowNotificationSettings(false)}>{t("app.close")}</Button>
                        <Button onClick={() => isSubscriptionExists ? handleNotificationChange() : handleNotificationCreation()}>{isSubscriptionExists ? t("app.confirm") : t("app.save")}</Button>
                    </div>
                </div>
            }
        </div>
    );
}

export default NotificationsSelector;
