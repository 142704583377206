import React, {Dispatch, FC, SetStateAction} from 'react';
import styles from './css/MobileWholeWeek.module.css';
import {temployee} from "../helpers/types";
import DayCard from "../utilities/DayCard";
import {HolidaysTypes} from "date-holidays";
import ControlsFilters from "../utilities/ControlsFilters";

interface IProps
{
    monthDays: Date[];
    sortedEmployees: temployee[];
    setIsWholeWeekView: Dispatch<SetStateAction<boolean>>;
    publicHolidays: HolidaysTypes.Holiday[];
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    currentEmployee: temployee;
    employees: temployee[];
}

const MobileWholeWeek: FC<IProps> = ({monthDays, sortedEmployees, setIsWholeWeekView, publicHolidays, selectedDays, setSelectedDays, currentEmployee, employees}) =>
{
    return (
        <div className={styles.whole_week_container}>
            <ControlsFilters currentEmployee={currentEmployee}
                             selectedDays={selectedDays} setSelectedDays={setSelectedDays}
                             employees={employees} publicHolidays={publicHolidays}
                             isSearchAvailable={false} isDeleteMultipleShiftsAvailable={false} isMobile={true} />

            <div className={styles.days_list}>
                {monthDays.map((day, index) =>
                {
                    return <DayCard key={index} day={day} publicHolidays={publicHolidays} selectedDays={selectedDays} setSelectedDays={setSelectedDays}
                                    isMobile={true}
                                    employees={employees} />
                })}
            </div>
        </div>
    );
}

export default MobileWholeWeek;
