import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/ShiftCard.module.css";
import {temployee, tshift} from "../helpers/types";
import Button from "./Button";
import Context from "../helpers/Context";
import ShiftModificationModal from "./ShiftModificationModal";
import {handleDeleteShift, shiftDurations, shiftTypeStyle} from "../helpers/helperFunctions";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {HolidaysTypes} from "date-holidays";
import {isAdmin, isUserAdmin} from "../helpers/checkers";
import {Icons} from "../helpers/Icons";

interface IProps
{
    shift: tshift | undefined;
    employee: temployee;
    employees: temployee[];
    setEmployees: Dispatch<SetStateAction<temployee[]>>;
    isOwner: boolean;
    selectedDays: Date[];
    day: Date;
    selectedShifts: tshift[],
    setSelectedShifts: Dispatch<SetStateAction<tshift[]>>;
    firstSlice: number;
    isMobile?: boolean;
    isShiftPage?: boolean;
    publicHolidays: HolidaysTypes.Holiday[];
    setDeleteEmployee?: Dispatch<SetStateAction<temployee | null>>;
}


const ShiftCard: FC<IProps> = ({shift, employee, employees, setEmployees, isOwner, selectedDays, day, selectedShifts, setSelectedShifts, firstSlice, isMobile = false, publicHolidays, isShiftPage = false, setDeleteEmployee}) =>
{
    const {accessToken, notificationDispatch, groupsID} = useContext(Context);
    const {t} = useTranslation();

    const [isAddShift, setIsAddShift] = useState<boolean>(false);
    const [isUpdateShift, setIsUpdateShift] = useState<boolean>(false);

    useEffect(() =>
    {
        setIsAddShift(false);
        setIsUpdateShift(false);
    }, [firstSlice])

    const handleSelectedShiftsArray = (shift: tshift) =>
    {
        if(!selectedShifts.includes(shift))
        {
            setSelectedShifts(current => [...current, shift]);
            return
        }

        setSelectedShifts(current => current.filter(selectedShift => selectedShift._id !== shift._id));
    }

    const handleDelete = async (shiftID: string) =>
    {
        const isDeleted = await handleDeleteShift(shiftID, accessToken, employees, employee, setEmployees, notificationDispatch);
        if(isDeleted)
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "success",
                        message: t(`backEnd.shiftDeleteSuccess`)
                    }
            })
        }
    }

    return (

        <div className={styles.card_main_container}>
            {isMobile &&
                <div className={styles.employee_name_mobile}>
                    {employee.name}
                    {isAdmin(groupsID) && <span onClick={() => setDeleteEmployee!(employee)} className={styles.delete_user_btn}>{Icons.delete}</span>}
                </div>
            }

            <div onClick={() => shift !== undefined && (isOwner || isAdmin(groupsID)) && isUserAdmin(groupsID) ? handleSelectedShiftsArray(shift) : (isOwner || isAdmin(groupsID)) && isUserAdmin(groupsID) && moment(day).isSameOrAfter(new Date(Date.now()), "day") && setIsAddShift(current => !current)} className={`${styles.card_container} 
            ${shift !== undefined ? styles[shiftTypeStyle(shift.type)] : null} 
            ${shift !== undefined && selectedShifts.includes(shift) ? styles.card_selected : null} `} style={{height: isShiftPage ? "100%" : "100px"}}>

                {((shift === undefined && (isOwner || isAdmin(groupsID)) && moment(day).isSameOrAfter(new Date(Date.now()), "day") && isUserAdmin(groupsID))
                    || (shift === undefined && isAdmin(groupsID))) &&
                    <Button iconType="add" disabled={(selectedDays.length === 1 && !selectedDays.includes(day)) || selectedDays.length >= 2}
                            onClick={e => { e.stopPropagation();
                                setIsAddShift(current => !current)
                            }} />
                }


                {shift !== undefined ?
                    <div className={styles.shift_content}>

                        {isShiftPage && <span className={styles.shift_date}>{moment(shift.date).format("DD.MM.yyyy")}</span>}

                        {shift.type.replace(" ", "").toLowerCase() !== "weekendcheck" ?

                            <div className={styles.shift_info}>
                                <span>{["morning", "day", "evening", "vacation", "sickLeave"].includes(shift.type) ? t(`shiftCard.${shift.type}`) : shift.type}{shift.isHalf ? ` (${t("shiftCard.half")})` : ""}</span>
                                {(shift.type.toLowerCase() !== "vacation" && shift.type.toLowerCase() !== "sickleave") && <span>{shiftDurations(shift)}</span>}
                            </div>
                        :
                            <div className={styles.weekends_shift_info}>
                                <div className={styles.weekend_morning}>
                                    <span>{t("shiftCard.morning")}</span>
                                    <span>{(process.env.REACT_APP_WEEKEND_MORNING as string).replace("-", " - ")}</span>
                                </div>
                                <div className={styles.weekend_evening}>
                                    <span>{t("shiftCard.evening")}</span>
                                    <span>{(process.env.REACT_APP_WEEKEND_EVENING as string).replace("-", " - ")}</span>
                                </div>
                            </div>
                        }
                  </div>
                    :
                    ((!isOwner && !isAdmin(groupsID)) || moment(day).isBefore(new Date(Date.now()), "day") || !isUserAdmin(groupsID)) && <span className={styles.none_shifts}>{t("app.none")}</span>
                }

                {(((shift !== undefined && (isOwner || isAdmin(groupsID)) && moment(day).isAfter(new Date(Date.now()), "day")) && isUserAdmin(groupsID))
                    || (shift !== undefined && isAdmin(groupsID))) &&
                    <div className={`${styles.card_control} ${shift.type.toLowerCase() === "weekend check" ? styles.card_controls_absolute : null}`} onClick={e => e.stopPropagation()}>
                        <Button btnType="danger" disabled={(selectedShifts.length === 1 && !selectedShifts.includes(shift)) || selectedShifts.length >= 2}
                                onClick={async () => await handleDelete(shift?._id)} iconType="delete" />
                        <Button iconType="edit" disabled={(selectedShifts.length === 1 && !selectedShifts.includes(shift)) || selectedShifts.length >= 2}
                                onClick={() => setIsUpdateShift(current => !current)} />
                    </div>
                }

                {(isAddShift || isUpdateShift) && (isOwner || isAdmin(groupsID)) &&
                    <ShiftModificationModal employee={employee}
                                            employees={employees}
                                            setEmployees={setEmployees}
                                            setCloseModal={isUpdateShift ? setIsUpdateShift : setIsAddShift}
                                            isUpdateShift={isUpdateShift} shift={shift} clickedShiftDate={day} publicHolidays={publicHolidays} />
                }

            </div>
        </div>
    );
}

export default ShiftCard;
