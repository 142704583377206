export const isAdmin = (groupsID: string[]): boolean =>
{
    return groupsID.some(id => id === process.env.REACT_APP_ADMIN_GROUP_ID);
}

export const isUser = (groupsID: string[]): boolean =>
{
    return groupsID.some(id => id === process.env.REACT_APP_USERS_GROUP_ID);
}

export const isUserAdmin = (groupsID: string[]): boolean =>
{
    return (groupsID.some(id => id === process.env.REACT_APP_USERS_GROUP_ID) || groupsID.some(id => id === process.env.REACT_APP_ADMIN_GROUP_ID));
}
