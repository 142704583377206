import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import styles from "./css/ShiftModificationModal.module.css";
import Button from "./Button";
import ShiftTypeSelector from "./ShiftTypeSelector";
import {temployee, tSelectShift, tshift} from "../helpers/types";
import {addShift, changeShift} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {useMsal} from "@azure/msal-react";
import moment from "moment";
import {inputValidation} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {HolidaysTypes} from "date-holidays";

interface IProps
{
    clickedShiftDate: Date;
    employee: temployee;
    employees: temployee[];
    setEmployees: Dispatch<SetStateAction<temployee[]>>;
    setCloseModal: Dispatch<SetStateAction<boolean>>;
    publicHolidays: HolidaysTypes.Holiday[];
    isUpdateShift?: boolean,
    shift?: tshift
}

const ShiftModificationModal: FC<IProps> = ({clickedShiftDate, employee, employees, setEmployees, setCloseModal, isUpdateShift= false, shift, publicHolidays}) =>
{
    const {accessToken, notificationDispatch} = useContext(Context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const ref = useRef<HTMLDivElement>(null);


    useEffect(() =>
    {
        document.addEventListener('mousedown', (event) =>
        {
            if (ref.current && !ref.current.contains(event.target as Node)) handleClose();
        });
    }, [ref]);


    const [selectedShift, setSelectedShift] = useState<tSelectShift>({
        fullTimeDuration: {from: "", to: ""}, otherExists: false,
        durationType: 0,
        isHalf: false, isHalfAvailable: false, type: ""});

    const handleAddShift = async () =>
    {
        if(inputValidation([{value: selectedShift.type, label: "Shift"}], notificationDispatch, t))
        {
            const newShift: tshift = await addShift(accessToken, {
                employeeEmail: employee.email, date: clickedShiftDate, ...selectedShift}, notificationDispatch);

            if(newShift)
            {
                const index = employees.indexOf(employee);
                setEmployees(prevState =>
                {
                    (prevState[index].shifts as tshift[]).push(newShift)
                    return [...prevState];
                });

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.shiftCreateSuccess`)
                        }
                })

            }
            handleClose();
        }
    }

    const handleChangeShift = async () =>
    {
        if(inputValidation([{value: selectedShift.type, label: "Shift"}], notificationDispatch, t))
        {
            const index = (employee.shifts as tshift[]).findIndex(shift => moment(clickedShiftDate).isSame(shift.date, "day"));
            const changedShift = await changeShift(accessToken, shift!._id, selectedShift, clickedShiftDate, notificationDispatch);

            if(changedShift)
            {
                (employee.shifts as tshift[])[index].type = selectedShift.type;
                (employee.shifts as tshift[])[index].isHalf = selectedShift.isHalf;
                (employee.shifts as tshift[])[index].durationType = selectedShift.durationType;
                (employee.shifts as tshift[])[index].fullTimeDuration = selectedShift.fullTimeDuration;
            }

            handleClose();
        }
    }

    const handleClose = () =>
    {
        setCloseModal(false);
    }

    return (
        <div className={styles.shift_modification_container} ref={ref}>
            <ShiftTypeSelector selectedShift={selectedShift} setSelectedShift={setSelectedShift} days={[clickedShiftDate]} isUpdate={isUpdateShift} publicHolidays={publicHolidays}
                               employeeEmail={employee.email} />

            <div className={styles.controls_container} onClick={e => e.stopPropagation()}>
                <Button onClick={e => {e.stopPropagation(); handleClose()}} btnType="danger">{t("app.cancel")}</Button>
                <Button onClick={() => isUpdateShift ? handleChangeShift() : handleAddShift()} disabled={!selectedShift.type.length}>{t("app.confirm")}</Button>
            </div>
        </div>
    );
}

export default ShiftModificationModal;
