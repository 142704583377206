import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import styles from './css/CustomSelect.module.css'
import {Icons} from "../helpers/Icons";
import {useTranslation} from "react-i18next";
import {tSelectShift} from "../helpers/types";
import {shiftDurations, upperCaseFirstLetter} from "../helpers/helperFunctions";
import _ from 'lodash';

interface IProps
{
    shifts: tSelectShift[];
    selectedShift: tSelectShift;
    setSelectedShift: Dispatch<SetStateAction<tSelectShift>>;
    width?: string;
    disabled?: boolean;
    isUpdate?: boolean;
}

const CustomShiftSelect: FC<IProps> = ({shifts, selectedShift, setSelectedShift, width, disabled, isUpdate}) =>
{
    const {t} = useTranslation();

    const [isSelectorClicked, setIsSelectorClicked] = useState<boolean>(false);
    const [isTimeSelectorClicked, setIsTimeSelectorClicked] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        document.addEventListener('mousedown', (event) =>
        {
            if (ref.current && !ref.current.contains(event.target as Node)) setIsSelectorClicked(false);
        });
    }, [ref]);

    useEffect(() =>
    {
        document.addEventListener('mousedown', (event) =>
        {
            if (ref2.current && !ref2.current.contains(event.target as Node)) setIsTimeSelectorClicked(false);
        });
    }, [ref2]);

    const handleSelect = (selectedOption: tSelectShift) =>
    {
        setSelectedShift(_.clone(selectedOption));
        setIsSelectorClicked(false);
    }

    const handleDurationChange = (duration: number) =>
    {
        selectedShift.durationType = duration;
        selectedShift.isHalf = duration !== 0;
        setIsTimeSelectorClicked(false);
    }

    return (
        <div className={styles.custom_shift_selector_container}>
            <label className={styles.select_filter}>
                <span className={styles.label}>{t("app.shift")}</span>
                <div ref={ref} className={`${styles.select} ${isSelectorClicked ? styles.multi_clicked : null} ${disabled ? styles.disabled : null}`}
                     onClick={() => setIsSelectorClicked(current => !current)} style={{width: width}}>

                    <div className={styles.selected_options_container}>
                        {selectedShift.type.length ?  <span>{["morning", "day", "evening", "vacation", "sickLeave"].includes(selectedShift.type) ? t(`shiftCard.${selectedShift.type}`) : selectedShift.type}{selectedShift.isHalf ? ` (${t("shiftCard.half")})` : ""}</span> :
                            <span className={styles.chosen}>{t("shiftTypeSelector.chooseShift")}</span>
                        }
                    </div>

                    {isSelectorClicked &&
                        <div className={styles.multi_selector} onClick={e => e.stopPropagation()}>
                            {shifts.map((option, index) =>
                                    <div key={index} onClick={e => {e.stopPropagation(); handleSelect(option)}}
                                         className={`${styles.option} ${selectedShift.type.toLowerCase() === option.type.toLowerCase() ? styles.selected : null}`}>{`${["morning", "day", "evening", "vacation", "weekend check", "sickLeave"].includes(option.type) ? t(`shiftCard.${option.type.replace(" ", "")}`) : option.type}${option.isHalf ? " (Half)" : ""}`}</div>)}
                        </div>
                    }

                    <div className={styles.dropdown_arrow}>
                        {isSelectorClicked ? Icons.ascArrow : Icons.descArrow}
                    </div>
                </div>
            </label>

            {selectedShift.type.length && selectedShift.type.toLowerCase() !== "vacation" && selectedShift.type.toLowerCase() !== "weekend check"
            && selectedShift.type.toLowerCase() !== "sickleave" ?
                <label>
                    <span className={styles.label}>{t("shiftTypeSelector.duration")}</span>
                    <div ref={ref2} className={`${styles.select} ${isTimeSelectorClicked ? styles.multi_clicked : null} ${disabled ? styles.disabled : null}`}
                         onClick={() => setIsTimeSelectorClicked(current => !current)} style={{width: width}}>

                        <div className={styles.selected_options_container}>
                            <span>{shiftDurations(selectedShift)}</span>
                        </div>

                        {isTimeSelectorClicked &&
                            <div className={styles.multi_selector}>
                                {( (!selectedShift.isHalf && selectedShift.isHalfAvailable) || (selectedShift.isHalf && !selectedShift.otherExists) ? [0, 1, 2] : [selectedShift.durationType])
                                    .map((duration, index) =>
                                        <div key={index} onClick={e => {e.stopPropagation(); handleDurationChange(duration);}}
                                             className={`${styles.option} ${selectedShift.durationType === duration ? styles.selected : null}`}>{shiftDurations({durationType: duration, fullTimeDuration: selectedShift.fullTimeDuration})}</div>

                                    )
                                }
                            </div>
                        }

                        <div className={styles.dropdown_arrow}>
                            {isTimeSelectorClicked ? Icons.ascArrow : Icons.descArrow}
                        </div>
                    </div>
                </label>
            : null}

            {selectedShift.type.toLowerCase() === "weekend check" &&
                <div className={styles.weekend_check_container}>
                    <span>Morning: {(process.env.REACT_APP_WEEKEND_MORNING as string).replace("-", " - ")}</span>
                    <span>Evening: {(process.env.REACT_APP_WEEKEND_EVENING as string).replace("-", " - ")}</span>
                </div>
            }
        </div>
    );
}

export default CustomShiftSelect;
