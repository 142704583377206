import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Shifts.module.css';
import moment from "moment";
import DayCard from "../utilities/DayCard";
import {useMsal} from "@azure/msal-react";
import {temployee, tshift} from "../helpers/types";
import {deleteUserByID, getAllEmployees} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {calculateMonth, getProperSlices, handleUserDelete} from "../helpers/helperFunctions";
import ShiftCard from "../utilities/ShiftCard";
import WeekFilter from "../utilities/WeekFilter";
import {useTranslation} from "react-i18next";
import ShiftsMobile from "./ShiftsMobile";
import Holidays from 'date-holidays';
import ControlsFilters from "../utilities/ControlsFilters";
import {isAdmin, isUserAdmin} from "../helpers/checkers";
import NotificationsSelector from "./NotificationsSelector";
import {Icons} from "../helpers/Icons";
import ConfirmActionScreen from "../utilities/ConfirmActionScreen";

function Shifts()
{
    const {accounts} = useMsal();
    const {accessToken, hideNotificationPrompt, setHideNotificationPrompt, notificationDispatch, groupsID} = useContext(Context);
    const {t} = useTranslation();

    const [monthDays, setMonthDays] = useState<Date[]>([]);
    const today = moment(new Date()).startOf("day").toDate();
    const [firstSlice, setFirstSlice] = useState<number>(0);
    const [secondSlice, setSecondSlice] = useState<number>(7);

    const [employees, setEmployees] = useState<temployee[]>([]);
    const sortedEmployees = [...employees.filter(employee => employee.name.toLowerCase() === (accounts[0].name as string).toLowerCase()),
        ...employees.filter(employee => employee.name.toLowerCase() !== (accounts[0].name as string).toLowerCase())]
    const currentEmployee = sortedEmployees[0];

    const [selectedDays, setSelectedDays] = useState<Date[]>([]);

    const [selectedShifts, setSelectedShifts] = useState<tshift[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const [isEmployeesFetched, setIsEmployeesFetched] = useState<boolean>(false);

    const [mobileSelectedDay, setMobileSelectedDay] = useState<Date>(moment(new Date(Date.now())).startOf("day").toDate());

    const hd = new Holidays('FI');
    const publicHolidays = hd.getHolidays(moment(mobileSelectedDay).isSame(new Date(), "year") ? undefined : mobileSelectedDay).filter(holiday => holiday.type === "public");

    const [deleteEmployee, setDeleteEmployee] = useState<temployee | null>(null);

    useEffect(() =>
    {
        (async () =>
        {
            setIsEmployeesFetched(false);
            const result: temployee[] = await getAllEmployees(accessToken, notificationDispatch);
            if(result)
            {
                setEmployees(result);

                if(isUserAdmin(groupsID))
                {
                    !result.filter(employee => employee.name === accounts[0].name).length &&
                    setEmployees(current => [{_id: "", name: accounts[0].name as string, email: accounts[0].username, shifts: []}, ...current])
                }

                setIsEmployeesFetched(true);
            }
        })();
    },[])

    useEffect(() =>
    {
        const month = calculateMonth(today)

        if(month.length)
        {
            getProperSlices(today, month, setFirstSlice, setSecondSlice);
            setMonthDays(month);
        }
    }, []);

    return (
        <div className={styles.shifts_container}>
            <WeekFilter monthDays={monthDays}
                        setMonthDays={setMonthDays}
                        firstSlice={firstSlice}
                        setFirstSlice={setFirstSlice}
                        secondSlice={secondSlice}
                        setSecondSlice={setSecondSlice}
                        setSelectedDays={setSelectedDays} setMobileSelectedDay={setMobileSelectedDay} />

            {window.innerWidth >= 1470 &&
                <>
                    {isUserAdmin(groupsID) ?
                        <div className={styles.notification_button}>
                            <NotificationsSelector />
                        </div> : null}
                    <ControlsFilters currentEmployee={currentEmployee}
                                     selectedDays={selectedDays} selectedShifts={selectedShifts} setSearchQuery={setSearchQuery}
                                     employees={employees} publicHolidays={publicHolidays} setEmployees={setEmployees} setSelectedShifts={setSelectedShifts}
                                     setSelectedDays={setSelectedDays} searchBy="name" />

                    <div className={styles.shift_list}>
                        <table>
                            <thead>
                            <tr>
                                <th>{t("app.employee")}</th>
                                {monthDays.slice(firstSlice, secondSlice).map((day, index) => <th id={day.toString()} key={index}>
                                    <DayCard publicHolidays={publicHolidays} day={day} selectedDays={selectedDays} setSelectedDays={setSelectedDays} employees={sortedEmployees} />
                                </th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {sortedEmployees.filter(employee => employee.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map(employee =>
                                    <tr key={employee._id}>
                                        <td className={styles.user_card}>
                                            <span>{employee.name}</span>
                                            {(isAdmin(groupsID) && employee._id.length) ? <span onClick={() => setDeleteEmployee(employee)} className={styles.delete_user_btn}>{Icons.delete}</span> : null}
                                        </td>
                                        {monthDays.slice(firstSlice, secondSlice).map((day, index) =>
                                        {
                                            const foundShift = (employee.shifts as tshift[])
                                                .find(shift => moment(shift.date).isSame(day, "day"));

                                            return <td key={index}>
                                                <ShiftCard employee={employee}
                                                           employees={employees}
                                                           setEmployees={setEmployees}
                                                           shift={foundShift}
                                                           isOwner={employee.email.toLowerCase() === accounts[0].username.toLowerCase()}
                                                           selectedDays={selectedDays} day={day}
                                                           selectedShifts={selectedShifts}
                                                           setSelectedShifts={setSelectedShifts}
                                                           firstSlice={firstSlice} publicHolidays={publicHolidays} />
                                            </td>
                                        })}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            }

            {window.innerWidth <= 1470 &&
                <ShiftsMobile monthDays={monthDays.slice(firstSlice, secondSlice)} sortedEmployees={sortedEmployees}
                              setEmployees={setEmployees} employees={employees}
                              firstSlice={firstSlice}
                              mobileSelectedDay={mobileSelectedDay} setMobileSelectedDay={setMobileSelectedDay}
                              publicHolidays={publicHolidays} currentEmployee={currentEmployee}
                              selectedDays={selectedDays} setSelectedDays={setSelectedDays}  />
            }

            {deleteEmployee !== null &&
                <ConfirmActionScreen close={() => setDeleteEmployee(null)} confirm={async () =>
                {
                    await handleUserDelete(deleteEmployee, setDeleteEmployee, setEmployees, accessToken, notificationDispatch);
                }}
                                     title={
                                        <div style={{display: "flex", flexDirection: "column"}}>{t("app.deleteUserTitle")} {deleteEmployee.shifts.length} {t("app.shifts")}?
                                            <span style={{fontSize: "21px", color: "var(--error)"}}>{t("app.deleteUserSubTitle")}</span>
                                        </div>
                                     }
                                     message={deleteEmployee.name} />
            }

        </div>
    );
}

export default Shifts;
