import React, {Dispatch, FC, SetStateAction} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from './css/ChangesNotSavedModal.module.css';
import Button from "./Button";
import {useTranslation} from "react-i18next";

interface IProps
{
    handleCloseDayModal: () => void;
    setIsCloseClicked: Dispatch<SetStateAction<boolean>>;
}

const ChangesNotSavedModal: FC<IProps> = ({handleCloseDayModal, setIsCloseClicked}) =>
{
    const {t} = useTranslation();

    const handleClose = () =>
    {
        setIsCloseClicked(false);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.not_saved_container}>
                        <span className={styles.header}>{t("notSavedModal.title")}</span>

                        <div className={styles.warning_messages}>
                            <span>{t("notSavedModal.warningMessage")}</span>
                            <span>{t("notSavedModal.warningMessageSubtitle")}</span>
                        </div>

                        <div className={styles.modal_controls}>
                            <Button btnType="danger" onClick={handleClose}>{t("app.cancel")}</Button>
                            <Button onClick={() => handleCloseDayModal()}>{t("app.confirm")}</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ChangesNotSavedModal;
