import React, {useContext, useEffect, useState} from 'react';
import styles from './css/UserShifts.module.css';
import {temployee, tshift} from "../helpers/types";
import {getUser} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import Button from "../utilities/Button";
import {useTranslation} from "react-i18next";
import ShiftCard from "../utilities/ShiftCard";
import ControlsFilters from "../utilities/ControlsFilters";

function UserShifts()
{
    const {accessToken, notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [employee, setEmployee] = useState<temployee[]>([]);
    const [selectedShifts, setSelectedShifts] = useState<tshift[]>([]);
    const [isActiveShifts, setIsActiveShifts] = useState<boolean>(true);

    const [searchQuery, setSearchQuery] = useState<string>("");

    useEffect(() =>
    {
        (async () =>
        {
            const employee: temployee[] = await getUser(accessToken, notificationDispatch);
            if(employee.length) setEmployee([...employee]);
        })();
    }, []);

    return (
        <div className={styles.shifts_container}>
            <div className={styles.shifts_controls}>
                <Button onClick={() => setIsActiveShifts(true)} btnType={`${isActiveShifts ? "primary" : "secondary"}`}>{t("userShifts.active")}</Button>
                <Button onClick={() => setIsActiveShifts(false)} btnType={`${!isActiveShifts ? "primary" : "secondary"}`}>{t("userShifts.inactive")}</Button>
            </div>

            <ControlsFilters isEditMultipleDaysAvailable={false} isAddMultipleShiftsAvailable={false} selectedShifts={selectedShifts} setSelectedShifts={setSelectedShifts}
                             employees={employee} setEmployees={setEmployee} setSearchQuery={setSearchQuery} searchBy="shiftType" />

            {employee.length && (employee[0].shifts as tshift[]).filter(shift => shift.active === isActiveShifts).length ?
            <div className={styles.shifts_list}>
                {employee.length && (employee[0].shifts as tshift[]).filter(shift => shift.active === isActiveShifts)
                    .filter(shift => shift.type.toLowerCase().includes(searchQuery.toLowerCase()))
                    .sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                    .map(shift =>
                    <div key={shift._id} className={styles.shift_card_wrapper}>
                        <ShiftCard day={shift.date} shift={shift}
                                   selectedShifts={selectedShifts} setSelectedShifts={setSelectedShifts}
                                   firstSlice={0} selectedDays={[]} employees={employee} publicHolidays={[]} isOwner={true}
                                   employee={employee[0]} setEmployees={setEmployee} isShiftPage={true} />
                    </div>
                )}
            </div>
                :
                <p>{t("app.noShiftFound")}</p>
            }
        </div>
    );
}

export default UserShifts;
