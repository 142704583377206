import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from '../utilities/css/AbsoluteWindowStyles.module.css';
import styles from "./css/DayModal.module.css"
import moment from "moment";
import {Icons} from "../helpers/Icons";
import {tday, temployee, tshift} from "../helpers/types";
import {getDayInfo, updateDay} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import Button from "../utilities/Button";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {shiftDurations, shiftTypeStyle, upperCaseFirstLetter} from "../helpers/helperFunctions";
import ChangesNotSavedModal from "../utilities/ChangesNotSavedModal";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {isUserAdmin} from "../helpers/checkers";


interface IProps
{
    day: Date;
    setCardClicked: Dispatch<SetStateAction<boolean>>;
    isHoliday?: boolean;
}

const DayModal: FC<IProps> = ({day, setCardClicked, isHoliday = false}) =>
{
    const {accessToken, notificationDispatch, setIsLoading, setLoadingMessage, groupsID} = useContext(Context);
    const {t} = useTranslation();

    const [dayInfo, setDayInfo] = useState<tday>({_id: "", comments: "", date: new Date(), notes: "", shifts: []});
    const [notes, setNotes] = useState<string>("");
    const [comments, setComments] = useState<string>("");

    const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true);
    const [isCloseClicked, setIsCloseClicked] = useState<boolean>(false);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);


    useEffect(() =>
    {
        if(day !== undefined)
        {
            (async () =>
            {
                const result: tday = await getDayInfo(accessToken, day, notificationDispatch);
                if(result)
                {
                    setDayInfo(result);
                    setNotes(result.notes);
                    setComments(result.comments);
                }

                setIsReadOnly(false);

            })();
        }
    }, [day]);

    const handleDayUpdate = async () =>
    {
        setIsLoading(true);
        setLoadingMessage(t("loadingMessages.updatingDay") as string);

        await updateDay(accessToken, day, {notes, comments}, notificationDispatch);

        setDayInfo(current =>
        {
            current.comments = comments;
            current.notes = notes;

            return current;
        })

        setIsChangesSaved(true);

        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "success",
                    message: t(`backEnd.dayUpdateSuccess`)
                }
        })

        setIsLoading(false);
        setLoadingMessage("");
    }

    useEffect(() =>
    {
        if(dayInfo.comments.length && !comments.replace(/<(.|\n)*?>/g, '').trim().length)
        {
            setComments("");
        }

        if(dayInfo.notes.length && !notes.replace(/<(.|\n)*?>/g, '').trim().length)
        {
            setNotes("");
        }

        if((comments.replace(/<(.|\n)*?>/g, '').trim().toLowerCase() !== dayInfo.comments.replace(/<(.|\n)*?>/g, '').trim().toLowerCase() ||
            notes.replace(/<(.|\n)*?>/g, '').trim().toLowerCase()  !== dayInfo.notes.replace(/<(.|\n)*?>/g, '').trim().toLowerCase()))
        {
            setIsChangesSaved(false)
        } else
        {
            setIsChangesSaved(true)
        }

    }, [notes, comments])

    const handleClose = () =>
    {
        setCardClicked(current => !current);
    }

    return (
        <div className={absoluteStyles.window_container}
             onClick={e => {e.stopPropagation(); isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}}
             onTouchEnd={(e) => {e.stopPropagation(); isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.day_modal_container}>

                        <div className={styles.top}>
                            <span className={`${isHoliday ? styles.holiday : null}`}>{upperCaseFirstLetter(moment(day).format("dddd"))}, {upperCaseFirstLetter(moment(day).format("MMM"))} ({moment(day).format("DD.MM.yyyy")})</span>
                            <span onClick={() => isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)} className={styles.close}>{Icons.close}</span>
                        </div>

                        <div className={styles.center}>

                            <div className={styles.shifts_container}>
                                <span>{upperCaseFirstLetter(t("app.shifts"))}</span>
                                <div className={styles.shifts_list}>
                                    {(dayInfo.shifts as tshift[]).filter(shift => shift.type.toLowerCase() !== "vacation" && shift.type.toLowerCase() !== "sickleave").length ?
                                    (dayInfo.shifts as tshift[]).filter(shift => shift.type.toLowerCase() !== "vacation" && shift.type.toLowerCase() !== "sickleave")
                                        .map(shift =>
                                            <span key={shift._id} className={`${styles.user_shift} ${styles[shiftTypeStyle(shift.type)]}`}>{`${(shift.userID as temployee).name}, 
                                            ${upperCaseFirstLetter(shift.type)} (${shiftDurations(shift)})`}</span>)
                                    :
                                        <p>{t("app.noShiftFound")}</p>
                                    }
                                </div>
                            </div>


                            <div className={styles.shifts_notes_comments_container}>

                                <div className={styles.notes}>
                                    <span>{`${t("app.notes")} ${notes.replace(/<(.|\n)*?>/g, '').trim().toLowerCase()  !== dayInfo.notes.replace(/<(.|\n)*?>/g, '').trim().toLowerCase() ? "*" : ""}`}</span>
                                    <div className={styles.text_editor_container}>
                                        <ReactQuill theme="snow" className={styles.text_editor} value={notes} onChange={setNotes} readOnly={isReadOnly || !isUserAdmin(groupsID)} />
                                    </div>
                                </div>

                                <div className={styles.comments}>
                                    <span>{`${t("app.comments")} ${comments.replace(/<(.|\n)*?>/g, '').trim().toLowerCase()  !== dayInfo.comments.replace(/<(.|\n)*?>/g, '').trim().toLowerCase() ? "*" : ""}`}</span>
                                    <div className={styles.text_editor_container}>
                                        <ReactQuill theme="snow" className={styles.text_editor} value={comments} onChange={setComments} readOnly={isReadOnly || !isUserAdmin(groupsID)} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.modal_controls}>
                            <Button btnType="danger" onClick={() => isChangesSaved ? handleClose() : setIsCloseClicked(current => !current)}>{t("app.close")}</Button>
                            {isUserAdmin(groupsID) && <Button onClick={handleDayUpdate} disabled={isChangesSaved}>{t("app.updateDay")}</Button>}
                        </div>
                    </div>

                </div>
            </div>

            {isCloseClicked &&
                <ChangesNotSavedModal handleCloseDayModal={handleClose} setIsCloseClicked={setIsCloseClicked} />
            }

        </div>
    );
}

export default DayModal;
