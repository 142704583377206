import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from './css/CustomShiftModal.module.css';
import Button from "./Button";
import {tSelectShift} from "../helpers/types";
import {EndTime, StartTime} from "./DatePickers";
import moment from "moment";
import {inputValidation, roundToNearest30Min} from "../helpers/helperFunctions";
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";

interface IProps
{
    setIsAddCustomShift: Dispatch<SetStateAction<boolean>>;
    setAvailableShifts: Dispatch<SetStateAction<tSelectShift[]>>
    selectedDate: Date,
}

const CustomShiftModal: FC<IProps> = ({setIsAddCustomShift, setAvailableShifts, selectedDate}) =>
{
    const {notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [type, setType] = useState<string>("");
    const [isHalfAvailable, setIsHalfAvailable] = useState<boolean>(true);

    const today = moment(new Date()).startOf("day").toDate();
    const [startTime, setStartTime] = useState<Date>(moment(selectedDate).isSame(today, "day") ? roundToNearest30Min(today) : selectedDate);
    const [endTime, setEndTime] = useState<Date>(moment(startTime).add(5, "hour").toDate());

    const handleCustomShiftAdd = () =>
    {
        if(moment(startTime).isBefore(today, "minute") || moment(endTime).isBefore(startTime, "minute") || moment(startTime).isAfter(endTime, "minute"))
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("shiftTypeSelector.customShiftTimeOverlap")
                    }
            });
        }


        if(inputValidation([{value: type, label: "Type"}], notificationDispatch, t))
        {
            setAvailableShifts(current => [...current, {type: type, isHalf: false, isHalfAvailable: isHalfAvailable, durationType: 0,
                fullTimeDuration: {from: moment(startTime).format("HH:mm"), to: moment(endTime).format("HH:mm")}}]);
            handleClose();
        }
    }

    const handleClose = () =>
    {
        setIsAddCustomShift(current => !current)
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.custom_shift_container}>
                        <label>
                            {t("shiftTypeSelector.customShiftTypeTitle")}
                            <input type="text" onChange={e => setType(e.target.value)}/>
                        </label>

                        <div className={styles.time_selectors}>
                            <StartTime endTime={endTime} startTime={startTime} setEndTime={setEndTime} setStartTime={setStartTime} />
                            <EndTime endTime={endTime} startTime={startTime} setEndTime={setEndTime} />
                        </div>

                        <label className={styles.half_checkbox}>
                            {t("shiftTypeSelector.customShiftTypeHalf")}
                            <input type="checkbox" checked={isHalfAvailable} onChange={e => setIsHalfAvailable(e.target.checked)}/>
                        </label>

                        <div className={styles.controls}>
                            <Button btnType="danger" onClick={handleClose}>{t("app.cancel")}</Button>
                            <Button onClick={handleCustomShiftAdd} disabled={!type.length}>{t("app.confirm")}</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CustomShiftModal;
