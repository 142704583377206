import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/ShiftsMobile.module.css';
import moment from "moment";
import {temployee, tshift} from "../helpers/types";
import ShiftCard from "../utilities/ShiftCard";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import DayModal from "./DayModal";
import {HolidaysTypes} from "date-holidays";
import Button from '../utilities/Button';
import {dayStatus, handleNotifications, handleUserDelete} from "../helpers/helperFunctions";
import Context from "../helpers/Context";
import MobileWholeWeek from "./MobileWholeWeek";
import {isUserAdmin} from "../helpers/checkers";
import NotificationsSelector from "./NotificationsSelector";
import ConfirmActionScreen from "../utilities/ConfirmActionScreen";

interface IProps
{
    monthDays: Date[];
    sortedEmployees: temployee[];
    employees: temployee[];
    setEmployees: Dispatch<SetStateAction<temployee[]>>;
    firstSlice: number;
    mobileSelectedDay: Date;
    setMobileSelectedDay: Dispatch<SetStateAction<Date>>;
    publicHolidays: HolidaysTypes.Holiday[];
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    currentEmployee: temployee;
}

const ShiftsMobile: FC<IProps> = ({monthDays, sortedEmployees, setEmployees, firstSlice, mobileSelectedDay, setMobileSelectedDay, publicHolidays, employees, selectedDays, setSelectedDays, currentEmployee}) =>
{
    const {accounts} = useMsal();
    const {t} = useTranslation();
    const {accessToken, notificationDispatch, setHideNotificationPrompt, hideNotificationPrompt, groupsID} = useContext(Context);

    const [cardClicked, setCardClicked] = useState<boolean>(false);
    const [isWholeWeekView, setIsWholeWeekView] = useState<boolean>(false);

    const [deleteEmployee, setDeleteEmployee] = useState<temployee | null>(null);

    useEffect(() =>
    {
        if(cardClicked || deleteEmployee !== null)
        {
            document.getElementsByTagName("body")[0].style.overflow = "hidden"
        } else
        {
            document.getElementsByTagName("body")[0].style.overflow = "auto"
        }

    }, [cardClicked, deleteEmployee])

    return (
        <div className={styles.shifts_mobile_container}>
            <div className={styles.shifts_mobile_controls}>
                <Button btnType={`${!isWholeWeekView ? "primary" : "secondary"}`} onClick={() => setIsWholeWeekView(false)}>{t("shiftMobile.dayView")}</Button>
                <Button btnType={`${isWholeWeekView ? "primary" : "secondary"}`} onClick={() => setIsWholeWeekView(true)}>{t("shiftMobile.weekView")}</Button>
            </div>
            {!isWholeWeekView &&
                <>
                    <div className={styles.days_list}>
                        {monthDays.map((day, index) =>
                            <div key={index} onClick={() => setMobileSelectedDay(day)}
                                 className={`${styles.day_card} ${moment(mobileSelectedDay).isSame(day, "day") ? styles.selected_day : null} 
                                            ${moment(day).isSame(new Date(), "day") ? styles.today : null} 
                                            ${publicHolidays.find(holiday => moment(holiday.date).isSame(day, "day")) ? styles.holiday : null} 
                                            ${styles[dayStatus(employees, day)]}`}>
                                <span>{moment(day).format("DD")}</span>
                            </div>)}
                    </div>
                    <div className={styles.day_data_container}>
                        <span>{moment(mobileSelectedDay).format("dddd, DD MMMM yyyy")}</span>
                        <button className={styles.additional_info_controls} onClick={() => setCardClicked(current => !current)}>{t("app.additionalInfo")}</button>
                    </div>

                    <div className={styles.employees_list}>
                        {sortedEmployees.map(employee =>
                        {
                            const foundShift = (employee.shifts as tshift[]).filter(shift => moment(shift.date).isSame(mobileSelectedDay, "day"));

                            return <ShiftCard key={employee._id}
                                              employee={employee}
                                              employees={employees}
                                              setEmployees={setEmployees}
                                              shift={foundShift.length ? foundShift[0] : undefined}
                                              isOwner={employee.email.toLowerCase() === accounts[0].username.toLowerCase()}
                                              selectedDays={[]} day={mobileSelectedDay}
                                              selectedShifts={[]}
                                              setSelectedShifts={() => []}
                                              firstSlice={firstSlice} isMobile={true} publicHolidays={publicHolidays} setDeleteEmployee={setDeleteEmployee} />
                        })}
                    </div>
                </>
            }

            {cardClicked &&
                <DayModal day={mobileSelectedDay} setCardClicked={setCardClicked} />
            }

            {isUserAdmin(groupsID) ?
                <div className={styles.bottom_menu}>
                    <NotificationsSelector isMobile={true} />
                </div> : null}

            {isWholeWeekView &&
                <MobileWholeWeek monthDays={monthDays}
                                 setIsWholeWeekView={setIsWholeWeekView}
                                 sortedEmployees={sortedEmployees}
                                 publicHolidays={publicHolidays} selectedDays={selectedDays} setSelectedDays={setSelectedDays}
                                 currentEmployee={currentEmployee} employees={employees} />
            }

            {deleteEmployee !== null &&
                <ConfirmActionScreen close={() => setDeleteEmployee(null)} confirm={async () =>
                {
                    await handleUserDelete(deleteEmployee, setDeleteEmployee, setEmployees, accessToken, notificationDispatch);
                }}
                                     title={
                                         <div style={{display: "flex", flexDirection: "column"}}>{t("app.deleteUserTitle")} {deleteEmployee.shifts.length} {t("app.shifts")}?
                                             <span style={{fontSize: "21px", color: "var(--error)"}}>{t("app.deleteUserSubTitle")}</span>
                                         </div>
                                     }
                                     message={deleteEmployee.name} />
            }

        </div>
    );
}

export default ShiftsMobile;
