import React, {FC, useContext} from 'react';
import {SyncLoader} from "react-spinners";
import styles from './css/Loader.module.css';
import absoluteStyles from './css/AbsoluteWindowStyles.module.css';
import Context from "../helpers/Context";

interface IProps
{
    isLoading: boolean;
}

const Loader: FC<IProps> = ({isLoading}) =>
{
    const {loadingMessage} = useContext(Context);

    return (
        <div className={absoluteStyles.window_container}>
            <div className={absoluteStyles.window_container_content}>
                <div className={styles.loader_content} style={{background: loadingMessage.length ? "#fff" : "transparent"}}>
                    <h1>{loadingMessage}</h1>
                    <SyncLoader color="var(--btnColor)" loading={isLoading} size={24} />
                </div>
            </div>
        </div>
    )
}

export default Loader;
