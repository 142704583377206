import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Statistics.module.css';
import moment from "moment";
import {additionalPayment, tstats} from "../helpers/types";
import {getAdditionalPayment, getStatsByMonth} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";
import {upperCaseFirstLetter} from "../helpers/helperFunctions";

function Statistics()
{
    const {accessToken, notificationDispatch, setIsLoading, setLoadingMessage, isLoading} = useContext(Context);
    const {t} = useTranslation();

    const [selectedMonth, setSelectedMonth] = useState<string>(moment(new Date(Date.now())).toISOString());
    const [morningStatistics, setMorningStatistics] = useState<tstats[]>([]);
    const [dayStatistics, setDayStatistics] = useState<tstats[]>([]);
    const [eveningStatistics, setEveningStatistics] = useState<tstats[]>([]);

    const [additionalPayment, setAdditionalPayment] = useState<{_id: string, additionalPayment: string }>({
        _id: "",
        additionalPayment: "0"
    });

    useEffect(() =>
    {
        (async () =>
        {
            const additionalPayment: additionalPayment = await getAdditionalPayment(accessToken, notificationDispatch, selectedMonth);
            if(additionalPayment) setAdditionalPayment(additionalPayment)
        })();
    }, [selectedMonth]);


    useEffect(() =>
    {
        (async () =>
        {
            setIsLoading(true);
            setLoadingMessage(t("loadingMessages.gettingStats") as string);

            const morningStats = await getStatsByMonth(accessToken, selectedMonth, "morning", notificationDispatch);
            const dayStats = await getStatsByMonth(accessToken, selectedMonth, "day", notificationDispatch);
            const eveningStats = await getStatsByMonth(accessToken, selectedMonth, "evening", notificationDispatch);

            if(morningStats) setMorningStatistics(morningStats);
            if(dayStats) setDayStatistics(dayStats);
            if(eveningStats) setEveningStatistics(eveningStats);

            setIsLoading(false);
            setLoadingMessage("");

        })();
    }, [selectedMonth])

    const handleMonthSelector  = (isBack: boolean = false) =>
    {
        if(isBack) return setSelectedMonth(current => moment(current).subtract(1, "month").toISOString());

        return setSelectedMonth(current => moment(current).add(1, "month").toISOString());
    }

    return (
        <div className={styles.statistics_container}>
            <div className={styles.controls}>
                <button onClick={() => handleMonthSelector(true)}> {"<"} </button>
                <span>{upperCaseFirstLetter(moment(selectedMonth).format("MMMM yyyy"))}</span>
                <button onClick={() => handleMonthSelector()}> {">"} </button>
            </div>

            {!isLoading &&
                <div className={styles.stats_tables_container}>
                    <div className={styles.table_content}>
                        <span className={styles.header}>{t("shiftCard.morning")}</span>
                        <table>
                            <thead>
                            <tr>
                                <th>{t("statistics.name")}</th>
                                <th>{t("statistics.shiftsNumber")}</th>
                                <th>{t("statistics.hours")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {morningStatistics.map((stat, index) =>
                                <tr key={index}>
                                    <td>{stat.name}</td>
                                    <td>{stat.count}</td>
                                    <td>{stat.hour}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.table_content}>
                        <span className={styles.header}>{t("shiftCard.day")}</span>
                        <table>
                            <thead>
                            <tr>
                                <th>{t("statistics.name")}</th>
                                <th>{t("statistics.shiftsNumber")}</th>
                                <th>{t("statistics.hours")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dayStatistics.map((stat, index) =>
                                <tr key={index}>
                                    <td>{stat.name}</td>
                                    <td>{stat.count}</td>
                                    <td>{stat.hour}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.table_content}>
                        <span className={styles.header}>{t("shiftCard.evening")}</span>
                        <table>
                            <thead>
                            <tr>
                                <th>{t("statistics.name")}</th>
                                <th>{t("statistics.shiftsNumber")}</th>
                                <th>{t("statistics.hours")}</th>
                                <th>{t("statistics.additionalPayment")} ({additionalPayment.additionalPayment} / {t("settingAdditionalPayment.perHour")})</th>
                            </tr>
                            </thead>
                            <tbody>
                            {eveningStatistics.map((stat, index) =>
                                <tr key={index}>
                                    <td>{stat.name}</td>
                                    <td>{stat.count}</td>
                                    <td>{stat.hour}</td>
                                    <td>{stat.additionalPay}€</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                </div>
            }

        </div>
    );
}

export default Statistics;
