import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import styles from './css/DayCard.module.css'
import shiftCardStyles from './css/ShiftCard.module.css';
import moment from "moment";
import DayModal from "../components/DayModal";
import {useTranslation} from "react-i18next";
import {HolidaysTypes} from "date-holidays";
import {temployee, tshift} from "../helpers/types";
import {dayStatus, initialsFromName, shiftTypeStyle, upperCaseFirstLetter} from "../helpers/helperFunctions";

interface IProps
{
    day: Date;
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    publicHolidays: HolidaysTypes.Holiday[];
    isMobile?: boolean;
    employees: temployee[];
}

const DayCard: FC<IProps> = ({day, selectedDays, setSelectedDays, publicHolidays, isMobile = false, employees}) =>
{
    const [cardClicked, setCardClicked] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleSelectedDaysArray = (day: Date) =>
    {
        if(!selectedDays.includes(day))
        {
            setSelectedDays(current => [...current, day]);
            return
        }

        setSelectedDays(current => current.filter(selectedDay => !moment(selectedDay).isSame(day, "day")))
    }

    return (
        <div className={`${styles.card_container} ${moment(day).isSame(new Date(Date.now()), "day") ? styles.card_today : null}
         ${selectedDays.includes(day) ? styles.card_selected : null} 
         ${publicHolidays.find(holiday => moment(holiday.date).isSame(day, "day")) ? styles.holiday : null} 
         ${styles[dayStatus(employees, day)]}`}
             onClick={() => handleSelectedDaysArray(day)}>
            <div className={styles.card_info}>
                <span className={styles.header}>{moment(day).format("DD")}</span>
                <div className={styles.additional_info}>
                    <span className={styles.week_day}>{upperCaseFirstLetter(moment(day).format("dddd"))}</span>
                    <span className={styles.month}>{upperCaseFirstLetter(moment(day).format("MMMM"))}</span>
                </div>
            </div>
            {isMobile &&
                <div className={styles.users_shift_container}>
                    {employees?.map(employee =>
                        {
                            const foundShift = (employee.shifts as tshift[])
                                .find(shift => moment(shift.date).isSame(day, "day"));

                            return foundShift !== undefined ?
                                <span key={employee._id} className={`${styles.shift} 
                            ${shiftCardStyles[shiftTypeStyle(foundShift.type)]}`}>{initialsFromName(employee.name)}</span> : null
                        }
                    )}
                </div>
            }
            <div className={styles.additional_info_controls} onClick={e => {e.stopPropagation(); setCardClicked(current => !current)}}>
                {t("app.additionalInfo")}</div>

            {cardClicked &&
                <DayModal day={day} setCardClicked={setCardClicked} isHoliday={publicHolidays.find(holiday => moment(holiday.date).isSame(day, "day")) !== undefined} />
            }
        </div>
    );
}

export default DayCard;
