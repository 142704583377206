import React, {useContext, useEffect, useState} from 'react';
import Button from "../utilities/Button";
import {getAdditionalPayment, updateAdditionalPayment} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";
import {additionalPayment} from "../helpers/types";
import moment from "moment";

function AdditionalPaymentSettings()
{
    const {accessToken, notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [additionalPayment, setAdditionalPayment] = useState<{_id: string, additionalPayment: string }>({
        _id: "",
        additionalPayment: "0"
    });

    const today = moment(new Date(Date.now())).endOf("month").toISOString();

    useEffect(() =>
    {
        (async () =>
        {
            const additionalPayment: additionalPayment = await getAdditionalPayment(accessToken, notificationDispatch, today);
            if(additionalPayment) setAdditionalPayment(additionalPayment)
        })();
    }, []);

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", textAlign: "center"}}>
            <label>
                <h1>{t("settingAdditionalPayment.title")}</h1>
                <input type="number" step="any" min={0} value={additionalPayment.additionalPayment}
                       onChange={e =>
                           setAdditionalPayment(current =>
                           {
                               return {_id: current._id, additionalPayment: e.target.value};
                           })} /> / {t("settingAdditionalPayment.perHour")}
            </label>
            <Button disabled={!additionalPayment.additionalPayment.length} onClick={async () => await updateAdditionalPayment(accessToken, additionalPayment.additionalPayment, notificationDispatch, today)}>{t("app.save")}</Button>
        </div>
    );
}

export default AdditionalPaymentSettings;
