import moment from "moment/moment";
import {Dispatch, SetStateAction} from "react";
import {deleteShift, deleteUserByID, getInactiveShiftsByMonth} from "./APIEnpoints";
import {temployee, tshift, notificationDispatch, tNotificationsPreference} from "./types";
import {TFunction} from "i18next";
import {v4} from "uuid";
import {subscribeUserToPush} from "../serviceWorkerRegistration";

export const calculateMonth = (startDay: Date) =>
{
    const numOfDays = moment(startDay).daysInMonth();
    const firstDay = moment(startDay).endOf("day").startOf("month");
    const monthDaysArr: Date[] = []

    for(let i = 0; i !== numOfDays; i++)
    {
        monthDaysArr.push(moment(firstDay).toDate());
        moment(firstDay.add(1, "day"));
    }

    return monthDaysArr
}

export const getProperSlices = (startDay: Date, month: Date[], setFirstSlice: Dispatch<SetStateAction<number>>, setSecondSlice: Dispatch<SetStateAction<number>>) =>
{
    const index = month.findIndex(day => moment(day).isSame(startDay,"day"));
    const sliceStart = moment(startDay).isoWeekday() !== 1 ? index - moment(startDay).isoWeekday() + 1 : index;

    setFirstSlice(sliceStart);
    setSecondSlice(sliceStart + 7);
}

export const upperCaseFirstLetter = (string: string) =>
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const initialsFromName = (name: string) =>
{
    const splitName = name.split(" ");
    const firstInitial = splitName[0].charAt(0).toUpperCase();
    const secondInitial = splitName[1].charAt(0).toUpperCase();

    return firstInitial + secondInitial;
}

export const handleDeleteShift = async (shiftID: string, accessToken: string, employees: temployee[], employee: temployee, setEmployees: Dispatch<SetStateAction<temployee[]>>, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    const result = await deleteShift(accessToken, shiftID, notificationDispatch);

    if(result)
    {
        const index = employees.indexOf(employee);

        setEmployees(prevState =>
        {
            prevState[index].shifts = (employee.shifts as tshift[]).filter(shift => shift._id !== shiftID);
            return [...prevState];
        });

        return true;
    }

    return false;
}

export const shiftDurations = (shift: {durationType: number, fullTimeDuration: {from: string, to: string}}) =>
{
    const fromDate = moment("2023-07-26").set("hour", parseInt(shift.fullTimeDuration.from))
        .set("minute", parseInt(shift.fullTimeDuration.from.split(":")[1])).toDate()
    const toDate = moment("2023-07-26").set("hour", parseInt(shift.fullTimeDuration.to))
        .set("minute", parseInt(shift.fullTimeDuration.to.split(":")[1])).toDate()

    const shiftHalf = moment(toDate).diff(fromDate, "minute", true)  / 2;

    return shift.durationType === 0 ? `${moment(fromDate).format("HH:mm")} - ${moment(toDate).format("HH:mm")}`:
        shift.durationType === 1 ? `${moment(fromDate).format("HH:mm")} - ${moment(fromDate).add(shiftHalf, "minute").format("HH:mm")}`
            : `${moment(fromDate).add(shiftHalf, "minute").format("HH:mm")} - ${moment(toDate).format("HH:mm")}`
}

export const shiftTypeStyle = (shiftType: string) =>
{
    switch(shiftType.toLowerCase())
    {
        case "morning":
            return "morning";
        case "day":
            return "day";
        case "evening":
            return "evening";
        case "vacation":
            return "vacation";
        case "weekend check":
            return "weekend_check"
        case "sickleave":
            return "sickLeave"
        default:
            return "custom"
    }
}

export const inputValidation = (inputsToValidate: {value: string, label: string}[], notificationDispatch: Dispatch<notificationDispatch>, t: TFunction<"translation", undefined, "translation">) =>
{
    for (const input of inputsToValidate)
    {
        if(!input.value.trim().length)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: `${input.label}${t("inputValidation.emptyError")}`
                    }
            });
        }
    }

    return true;
}

export const roundToNearest30Min = (date: Date) =>
{
    const remainder = 30 - (moment(date).minute() % 30);
    return moment(date).add(remainder, "minutes").startOf("minute").toDate();
}

export const handleNotifications = async (notificationsPreference: tNotificationsPreference, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>, setHideNotificationPrompt: Dispatch<SetStateAction<boolean>>) =>
{
    await Notification.requestPermission(async result =>
    {
        if (result !== "granted") throw new Error("We weren't granted permission.");

        await subscribeUserToPush(notificationsPreference, notificationDispatch, accessToken);
        setHideNotificationPrompt(true);
    })
}

export const dayStatus = (employees: temployee[], day: Date) =>
{
    let mandatoryShiftsCount = 0;

    employees.filter(employee =>
    {
        const foundShift = (employee.shifts as tshift[])
            .find(shift => moment(shift.date).isSame(day, "day"));

        if(foundShift !== undefined && ["morning", "day", "evening", "weekend check"].includes(foundShift.type))
        {
            mandatoryShiftsCount += foundShift.isHalf ? 0.5 : 1;
        }
    })

    switch (mandatoryShiftsCount)
    {
        case 0:
            return "incomplete"
        case 1:
            return (moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7 ) ? "complete" : "halfComplete"
        case 2:
            return "halfComplete";
        case 3:
            return "complete"
        default:
            return "incomplete"
    }
}

export const handleUserDelete = async (deleteEmployee: temployee | null, setDeleteEmployee: Dispatch<SetStateAction<temployee | null>>, setEmployees: Dispatch<SetStateAction<temployee[]>>, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    await deleteUserByID(deleteEmployee!._id, accessToken, notificationDispatch);
    setDeleteEmployee(null);
    setEmployees(current => current.filter(employee => employee._id !== deleteEmployee?._id))
}
