import React, {useContext, useEffect} from 'react';
import './index.css';
import {Route, BrowserRouter as Router, Routes, Navigate} from "react-router-dom";
import NavBar from "./components/NavBar";
import {RequestAccessToken} from "./helpers/APIEnpoints";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication
} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";
import {loginRequest} from "./authConfig";
import Context from "./helpers/Context";
import Shifts from "./components/Shifts";
import {get} from "idb-keyval";
import './i18n';
import Loader from "./utilities/Loader";
import Statistics from "./components/Statistics";
import UserShifts from "./components/UserShifts";
import AdditionalPaymentSettings from "./components/AdditionalPaymentSettings";
import 'moment/locale/fi'
import 'moment/locale/en-gb'
import {isAdmin, isUserAdmin} from "./helpers/checkers";
import NotFound from "./components/NotFound";

function App()
{
    const { instance, accounts } = useMsal();
    const {groupsID, setGroupsID, setAccessToken, accessToken, setHideNotificationPrompt, isLoading} = useContext(Context);
    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();
    const {login, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() =>
    {
        (async () =>
        {
            if (error instanceof InteractionRequiredAuthError) await login(InteractionType.Redirect, loginRequest);
        })();
    }, [error]);

    useEffect(() =>
    {
        if(isAuthenticated)
        {
            RequestAccessToken(accounts, instance, setAccessToken);
            if(accounts[0].idTokenClaims !== undefined && Array.isArray(accounts[0].idTokenClaims.groups)) setGroupsID(accounts[0].idTokenClaims.groups);
        }

    }, [isAuthenticated]);

    useEffect(() =>
    {
        (async () =>
        {
            const endpoint =  await get("subscriptionEndpoint");
            setHideNotificationPrompt(endpoint !== undefined || Notification.permission === "granted" || Notification.permission === "denied");
        })();
    }, []);

  return (
      <Router>
          <NavBar />
          {accessToken !== "" ?
              <div className="main-container">
                  <Routes>
                      <Route path="*" element={<NotFound />}/>
                      <Route path="/" element={<Shifts />}/>
                      <Route path="/my-shifts" element={
                          <>
                              <AuthenticatedTemplate>
                                  {isUserAdmin(groupsID) ? <UserShifts /> : <p>{t("app.accessError")}</p>}
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>
                      }/>
                      <Route path="/stats" element={
                          <>
                              <>
                                  <AuthenticatedTemplate>
                                      {isUserAdmin(groupsID) ? <Statistics /> : <p>{t("app.accessError")}</p>}
                                  </AuthenticatedTemplate>
                                  <UnauthenticatedTemplate>
                                      <p>{t("app.unAuthError")}</p>
                                  </UnauthenticatedTemplate>
                              </>
                          </>
                      }/>
                      <Route path="/settings" element={
                          <>
                              <>
                                  <AuthenticatedTemplate>
                                      {isAdmin(groupsID) ? <AdditionalPaymentSettings /> : <p>{t("app.accessError")}</p>}
                                  </AuthenticatedTemplate>
                                  <UnauthenticatedTemplate>
                                      <p>{t("app.unAuthError")}</p>
                                  </UnauthenticatedTemplate>
                              </>
                          </>
                      }/>
                  </Routes>

                  {isLoading &&
                      <Loader isLoading={isLoading} />
                  }
              </div>
              :
              <>
                  {isAuthenticated && accessToken !== "" && <h1>{t("app.accessError")}</h1>}
              </>
          }
      </Router>
  );
}

export default App;
