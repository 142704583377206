import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import context from "../helpers/Context";
import {useMsal} from "@azure/msal-react";
import {temployee, tSelectShift, tshift} from "../helpers/types";
import {addShift} from "../helpers/APIEnpoints";
import styles from './css/AddMultipleShiftsModal.module.css';
import modalStyles from './css/ModalStyles.module.css'
import moment from "moment";
import ShiftTypeSelector from "./ShiftTypeSelector";
import Button from "./Button";
import {inputValidation} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {HolidaysTypes} from "date-holidays";
interface IProps
{
    selectedDays: Date[];
    setSelectedDays: Dispatch<SetStateAction<Date[]>>;
    setIsAddMultipleShifts: Dispatch<SetStateAction<boolean>>;
    employees: temployee[];
    publicHolidays: HolidaysTypes.Holiday[];
}

const AddMultipleShiftsModal: FC<IProps> = ({selectedDays, setSelectedDays, setIsAddMultipleShifts, employees, publicHolidays}) =>
{
    const {accessToken, notificationDispatch, setLoadingMessage, setIsLoading} = useContext(context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const [selectedShift, setSelectedShift] = useState<tSelectShift>({
        fullTimeDuration: {from: "", to: ""}, otherExists: false,
        durationType: 0,
        isHalf: false, isHalfAvailable: false, type: ""});

    const handleAddMultipleShifts = async() =>
    {
        if(inputValidation([{value: selectedShift.type, label: "Shift"}], notificationDispatch, t))
        {
            setIsLoading(true);
            setLoadingMessage(t("loadingMessages.addingSelectedShifts") as string);

            for(const selectedDay of selectedDays)
            {
                const newShift: tshift = await addShift(accessToken, {employeeEmail: accounts[0].username, date: selectedDay, ...selectedShift}, notificationDispatch);

                const index = employees.findIndex(employee => employee.name === accounts[0].name);
                (employees[index].shifts as tshift[]).push(newShift);
            }

            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "success",
                        message: t(`backEnd.shiftCreateSuccess`)
                    }
            })

            handleClose();
        }
    }

    const handleClose = () =>
    {
        setIsAddMultipleShifts(current => !current);
        setSelectedDays([]);
        setIsLoading(false);
        setLoadingMessage("");
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.multiple_shifts_container}>
                        <div className={modalStyles.modal_top}>
                            {t("addMultipleShiftModal.title")}({selectedDays.length} {t("app.days")}):
                            <div className={modalStyles.selected_days_list}>
                                {selectedDays.map((day, index) => <span key={index}>{moment(day).format("DD.MM.yyyy")}</span>)}
                            </div>
                        </div>

                        <ShiftTypeSelector selectedShift={selectedShift} setSelectedShift={setSelectedShift} days={selectedDays} isUpdate={false} publicHolidays={publicHolidays}
                                           employeeEmail={accounts[0].username} />

                        <div className={modalStyles.modal_controls}>
                            <Button btnType="danger" onClick={handleClose}>{t("app.cancel")}</Button>
                            <Button onClick={handleAddMultipleShifts} disabled={!selectedShift.type.length}>{t("app.confirm")}</Button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddMultipleShiftsModal;
